import { useOrganizationDetailsViewVendor } from '../wrappers/useOrganizationDetailsViewVendor'
import { OrganizationDetailsView } from '../../../components/Organization/organization-details-view.component'
import { Organization } from '../../../../core/models/entities/Organization'
import { User } from '../../../../core/models/entities/User'

interface OrganizationDetailsViewProps {
  users: User[]
  organization: Organization,
}

export function OrganizationDetailsViewVendorWrapper({ organization }: OrganizationDetailsViewProps) {
  const vm = useOrganizationDetailsViewVendor(organization)
  return <OrganizationDetailsView {...vm} />
}