import { Organization } from '../../../../core/models/entities/Organization'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import { CreateUserDto } from '../../../../core/models/dtos/CreateUserDto'
import { OrganizationDetailsView_VM } from '../../../components/Organization/organization-details-view.component'
import { useNavigate, useParams } from 'react-router-dom'
import { PageQuery, toInAppQueryParam } from '../../../../utils/models/pageQuery'
import { withToasts } from '../../../components/Toast/Toast'
import { useState } from 'react'

export function useOrganizationDetailsViewVendor(organization: Organization): OrganizationDetailsView_VM {
  const { userRepository } = useRepositoriesContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const {
    data: users,
    isError: isUsersError,
    isLoading: isUsersLoading,
    isSuccess: isUsersSuccess
  } = userRepository.useUsersOfOrganization(organization.id)

  function handleCreateUser(user: CreateUserDto) {

    async function transaction() {
      user.organizationId = organization.id
      return await userRepository.createUser(user)
    }

    return withToasts(
      transaction,
      'Creating user',
      'User created',
      'Failed to create user',
      setIsLoading
    )
  }

  function handleDeleteUser(userId: number) {
    async function transaction() {
      return await userRepository.deleteUser(userId)
    }

    return withToasts(
      transaction,
      'Deleting user',
      'User deleted',
      'Failed to delete user',
      setIsLoading
    )
  }

  function handleBranchClick(organization: Organization) {
    if (organization.isVendor) {
      navigate(`/vendor/${organization.parent}`)
    }
    else {
      navigate(`/customer/${organization.id}`)
    }
  }

  function handleToMainBranch() {
    navigate(`/vendor/${organization.mainBranchId}`)
  }

  function handleToCustomers() {
    const page = {
      page: {
        index: 1,
        size: 25,
      },
      filterBy: [
        {
          value: '' + organization.id,
          field: 'parent.id'
        }
      ]
    } as PageQuery
    const s = toInAppQueryParam(page);
    navigate(`/customer${s}`)
  }

  const subject = 'Vendor'

  return {
    users: users || [],
    subject,
    onCreateUser: handleCreateUser,
    onDeleteUser: handleDeleteUser,
    organization,
    onBranchClick: handleBranchClick,
    onToMainBranch: handleToMainBranch,
    onToCustomers: handleToCustomers,
  }
}