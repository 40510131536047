import { Organization } from '../../../core/models/entities/Organization'
import { User } from '../../../core/models/entities/User'
import { CreateUserDto } from '../../../core/models/dtos/CreateUserDto'
import { JetSportBox } from '../BoxView/JetSportBox'
import { BoxHeader2 } from '../BoxView/BoxHeader2'
import { BoxHeaderTypography } from '../Typographies/BoxHeaderTypography'
import { BoxContent } from '../BoxView/BoxContent'
import { BoxFooter } from '../BoxView/BoxFooter'
import { OrganizationDetails } from './organization-details.component'
import { UserList } from './user-list.component'

export interface OrganizationDetailsView_VM {
  subject: string,
  organization: Organization,
  users: User[],
  onCreateUser: (user: CreateUserDto) => Promise<void>
  onDeleteUser: (userId: number) => Promise<void>
  onBranchClick: (branch: Organization) => void
  onToMainBranch: () => void
  onToCustomers: () => void
  onToEdit?: () => void
}

export function OrganizationDetailsView({ organization, subject, onCreateUser, onDeleteUser, users, onBranchClick, onToMainBranch, onToCustomers, onToEdit }: OrganizationDetailsView_VM) {
  return <JetSportBox>
    <BoxHeader2>
      <BoxHeaderTypography>{subject} - {organization.name}</BoxHeaderTypography>
    </BoxHeader2>
    <BoxContent>
      <OrganizationDetails onToMainBranch={onToMainBranch} onToCustomers={onToCustomers} onBranchClick={onBranchClick} organization={organization} onToEdit={onToEdit}></OrganizationDetails>
      <UserList users={users} onCreate={onCreateUser} onDelete={onDeleteUser} />
    </BoxContent>
    <BoxFooter></BoxFooter>
  </JetSportBox>
}