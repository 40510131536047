import { JSX, useState } from 'react'
import { useDialog } from '../../../components/Dialogs/dialog-base'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { OrderDialog } from './OrderDialog'

interface Props {
  component: JSX.Element
  open: () => void
}

export function useDeliveryBeforeOrderDialog({
  onContinue,
  onCancel
}: {onContinue: () => Promise<void>, onCancel?: () => void}): Props {

  const [loading, setLoading] = useState(false)
  const [toggle, setToggle] = useState<boolean>(false); 

  const dialog = useDialog()
  const { profile } = useProfileContext()

  function handleSubmit() {
    setLoading(true);
    onContinue().finally(() => {
      setLoading(false)
      setToggle(false);
      dialog.closeDialog()
    })
  }

  function handleToggle() {
    setToggle(!toggle)
  }

  function handleCancel() {
    if (onCancel) onCancel()
    dialog.closeDialog()
  }

  return {
    component: <>
      <OrderDialog 
        dialogVm={dialog}
        onToggle={handleToggle}
        onSubmit={handleSubmit}
        loading={loading}
        onCancel={handleCancel}
        toggle={toggle}
        termsText={profile!.orderOptions.orderOptionsTermsOfDelivery}
        title="Delivery before kl. 12:00 with GLS"
        explanation="The current fee for delivery with GLS before 12:00 PM is "
        boldPrice={"DKK " + profile!.orderOptions.orderOptionsDeliveryCost}
      />
    </>,
    open: dialog.openDialog
  }
}