import { SortDirection } from '../../presentation/components/GenericTable/Models/sortDirection'

export interface PageQuery {
  searchTerm: string,
  filterBy: FilterBy[],
  sortBy: {
    field: string,
    sortDirection?: SortDirection
  }
  page: {
    size: number,
    index: number
  }
  open?: {
    id: number
    organizationId: number
  }
}

export interface FilterBy {
  field: string,
  value: string,
}


export function toQueryParam(queryObj: PageQuery): string {
  const querySegments = []
  if (queryObj.page?.size) querySegments.push(`pageSize=${queryObj.page.size}`)
  if (queryObj.page?.index) querySegments.push(`pageIndex=${queryObj.page.index}`)
  if (queryObj.searchTerm) querySegments.push(`searchTerm=${queryObj.searchTerm}`)
  if (queryObj.sortBy?.field) querySegments.push(`sortByField=${queryObj.sortBy.field}`)
  if (queryObj.sortBy?.sortDirection) querySegments.push(`sortByDirection=${queryObj.sortBy.sortDirection}`)
  if (queryObj.filterBy && queryObj.filterBy.length > 0) {
    for (const filter of queryObj.filterBy) {
      if (!filter.field) continue
      querySegments.push(`filterByField=${filter.field}`)
      querySegments.push(`filterByValue=${filter.value}`)
    }
  }

  let queryString: string = ''
  for (let i = 0; i < querySegments.length; i++) {
    let symbol
    if (i === 0) {
      symbol = '?'
    } else {
      symbol = '&'
    }

    queryString += symbol + querySegments[i]
  }

  return queryString
}

export function toInAppQueryParam(queryObj: PageQuery): string {
  const querySegments = []
  if (queryObj.page?.size) querySegments.push(`pageSize=${queryObj.page.size}`)
  if (queryObj.page?.index) querySegments.push(`pageIndex=${queryObj.page.index}`)
  if (queryObj.searchTerm) querySegments.push(`searchTerm=${queryObj.searchTerm}`)
  if (queryObj.sortBy?.field) querySegments.push(`sortByField=${queryObj.sortBy.field}`)
  if (queryObj.sortBy?.sortDirection) querySegments.push(`sortByDirection=${queryObj.sortBy.sortDirection}`)
  if (queryObj.filterBy && queryObj.filterBy.length > 0) {
    for (const filter of queryObj.filterBy) {
      if (!filter.field) continue
      querySegments.push(`filterOn=${filter.value}`)
      querySegments.push(`filterBy=${filter.field}`)
    }
  }

  let queryString: string = ''
  for (let i = 0; i < querySegments.length; i++) {
    let symbol
    if (i === 0) {
      symbol = '?'
    } else {
      symbol = '&'
    }

    queryString += symbol + querySegments[i]
  }

  return queryString
}



