import { Organization } from '../../../core/models/entities/Organization'
import { User } from '../../../core/models/entities/User'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { CreateUserDto } from '../../../core/models/dtos/CreateUserDto'
import { OrganizationDetailsView_VM } from '../../components/Organization/organization-details-view.component'
import { useNavigate } from 'react-router-dom'
import { PageQuery, toInAppQueryParam } from '../../../utils/models/pageQuery'
import { useState } from 'react'
import { withToasts } from '../../components/Toast/Toast'

export function useOrganizationDetailsViewCustomer(organization: Organization, users: User[]): OrganizationDetailsView_VM {
  const { userRepository } = useRepositoriesContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate()


  function handleCreateUser(user: CreateUserDto) {
    async function transaction() {
      user.organizationId = organization.id
      return await userRepository.createUser(user)
    }

    return withToasts(
      transaction,
      'Creating user',
      'User created',
      'Failed to create user',
      setIsLoading
    )
  }


  function handleDeleteUser(userId: number) {
    async function transaction() {
      return await userRepository.deleteUser(userId)
    }

    return withToasts(
      transaction,
      'Deleting user',
      'User deleted',
      'Failed to delete user',
      setIsLoading
    )
  }

  function handleBranchClick(branch: Organization) {
    navigate(`/vendor/${branch.id}`)
  }

  function handleToMainBranch() {
    navigate(`/vendor/${organization.mainBranchId}`)
  }

  function handleToCustomers() {
    const page = {
      page: {
        index: 1,
        size: 25,
      },
      filterBy: [
        {
          field: 'parent.id',
          value: '' + organization.id
        }
      ]
    } as PageQuery
    const s = toInAppQueryParam(page);
    navigate(`/customer${s}`)
  }

  function handleToEdit() {
    navigate(`edit`)
  }

  const subject = 'Customer'
  return {
    users,
    subject,
    onCreateUser: handleCreateUser,
    onDeleteUser: handleDeleteUser,
    organization,
    onBranchClick: handleBranchClick,
    onToMainBranch: handleToMainBranch,
    onToCustomers: handleToCustomers,
    onToEdit: handleToEdit
  }
}