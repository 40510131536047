import { JetSportBox } from '../../components/BoxView/JetSportBox'
import { BoxHeader2 } from '../../components/BoxView/BoxHeader2'
import { BoxContent } from '../../components/BoxView/BoxContent'
import { InvertedPanel } from '../../components/BoxView/InvertedPanel'
import { OrderLinesList } from './subcomponents/OrderLinesList'
import { OrderLineView } from './subcomponents/OrderLineView'

import { CartItem } from './models/CartItem'
import { useAddToOrderDialog } from '../../components/Dialogs/add-to-order-dialog'
import { ExtraLogo, HeatingOption, useAddHeatingFeeOrderDialog } from '../order-confirmation/dialogs/add-heating-fee-order-dialog'
import { useCartContext } from '../../../contexts/CartContext'

export interface OrderCreateViewModel {
  selectedOrderLineId: string | undefined
  setSelectedOrderLineId: (id: string | undefined) => void
  orderlines: CartItem[]
  handleRemoveOrderLine: (id: string) => void
  selectOrderLineHandler: (orderLine: CartItem) => void
  removeOrderLineHandler: (orderLine: CartItem) => void
  handleContinue: (heatingOption: HeatingOption, extraLogos: ExtraLogo[]) => Promise<void>
  isLoading: boolean
  isValid: boolean
}

export function OrderCreateComponent({ selectedOrderLineId, orderlines, selectOrderLineHandler, removeOrderLineHandler, handleContinue, isValid }: OrderCreateViewModel) {

  const addToOrderDialog = useAddToOrderDialog()
  const heatingFeeDialog = useAddHeatingFeeOrderDialog({ onContinue: handleContinue })
  const hasDesigns = orderlines.filter(x => x.type === 'Design')?.length ? true : false
  const { activeOrder } = useCartContext()

  function addOrderLineHandler() {
    addToOrderDialog.open();
  }

  function openHeatingFeeDialogHandler() {
    heatingFeeDialog.open();
  }

  const onContinue = hasDesigns
    ? () => openHeatingFeeDialogHandler()
    : () => handleContinue(HeatingOption.none, [])

  const selectedOrderLine = orderlines.find(x => x.id === selectedOrderLineId)

  return <JetSportBox className={'min-h-[900px]'}>
    {addToOrderDialog.component}
    {heatingFeeDialog.component}
    <BoxHeader2 className={'uppercase text-sm font-bold flex justify-between items-center'}>
      PLACE ORDER {activeOrder && `- #${activeOrder!.orderNumber}`}
    </BoxHeader2>
    <BoxContent>
      <InvertedPanel className={'h-109'}>
        <OrderLinesList
          orderLines={orderlines}
          onAddOrderLine={addOrderLineHandler}
          onRemoveOrderLine={removeOrderLineHandler}
          onSelectOrderLine={selectOrderLineHandler}
          handleContinue={onContinue}
          isValid={isValid}
        />
      </InvertedPanel>
      <div className={'h-full flex flex-1 flex-col'}>
        <div className={'flex flex-1 pt-4'}>
          {selectedOrderLine && <OrderLineView orderline={selectedOrderLine} />}
        </div>
      </div>
    </BoxContent>
  </JetSportBox>
}