import { useState } from 'react'
import { Profile } from '../../../../core/models/entities/Profile'
import { OrderOptionsEditor, OrderOptionsEditorViewModel, OrderOptionsForm } from './order-options-editor.component'

interface WrapperProps {
  profile: Profile
  onFormChange: (form: OrderOptionsForm) => void
  disabled: boolean
  initialState: boolean
}

export function OrderOptionsEditorWrapper({ disabled, onFormChange, initialState, profile }: WrapperProps) {
  const vm = useViewModel(profile, onFormChange, disabled, initialState)
  return <OrderOptionsEditor {...vm} />;
}

function useViewModel(
  profile: Profile,
  onFormChange: (form: OrderOptionsForm) => void,
  disabled: boolean,
  initialState: boolean
): OrderOptionsEditorViewModel {

  const [toggleState, setToggleState] = useState(initialState);

  const form = {
    orderOptionsTermsOfDelivery: profile.orderOptions?.orderOptionsTermsOfDelivery ?? '',
    orderOptionsTermsOfStandardPackaging: profile.orderOptions?.orderOptionsTermsOfStandardPackaging ?? '',
    orderOptionsTermsOfSpecificPackaging: profile.orderOptions?.orderOptionsTermsOfSpecificPackaging ?? '',
    orderOptionsDeliveryCost: profile.orderOptions?.orderOptionsDeliveryCost ?? 0,
    orderOptionsStandardPackagingCost: profile.orderOptions?.orderOptionsStandardPackagingCost ?? 0,
    orderOptionsSpecificPackagingCost: profile.orderOptions?.orderOptionsSpecificPackagingCost ?? 0,
  }

  const toggle = () => { setToggleState(!toggleState) }

  return {
    disabled,
    form,
    onFormChange,
    initialState: toggleState,
    onToggle: toggle
  };
}
