import { Section } from '../../../components/StructureElements/Section'
import { SectionHeaderTypography } from '../../../components/Typographies/SectionHeaderTypography'
import React, { useCallback } from 'react'
import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { InputWrapper } from '../../../components/Input/InputWrapper'
import { SectionContent } from '../SectionContent'
import { SectionHeader } from '../SectionHeader'
import { CustomInputChangeEvent } from '../../../../utils/models/events/CustomInputChangeEvent'
import { InputLabel } from '../../../components/Input/InputLabel'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { InputOrderOptionsEditor } from './input-order-options-editor.component'
import { Stepper } from '../../../components/Buttons/Stepper'

export interface OrderOptionsForm {
  orderOptionsTermsOfDelivery: string
  orderOptionsTermsOfStandardPackaging: string
  orderOptionsTermsOfSpecificPackaging: string
  orderOptionsDeliveryCost?: number
  orderOptionsStandardPackagingCost: number
  orderOptionsSpecificPackagingCost: number
}

export interface OrderOptionsEditorViewModel {
  onFormChange: (form: OrderOptionsForm) => void
  disabled: boolean
  form: OrderOptionsForm
  initialState: boolean
  onToggle: () => void
}

export function OrderOptionsEditor({ disabled, onFormChange, initialState, form, onToggle}: OrderOptionsEditorViewModel) {

  const onDeliveryChange = useCallback((value: number) => {
    form.orderOptionsDeliveryCost = value
    onFormChange(form)
  }, [form])

  const onStandardPackagingChange = useCallback((value: number) => {
    form.orderOptionsStandardPackagingCost = value
    onFormChange(form)
  }, [form])

  const onSpecificPackagingChange = useCallback((value: number) => {
    form.orderOptionsSpecificPackagingCost = value
    onFormChange(form)
  }, [form])

  function onOptionsChange(event: React.ChangeEvent<HTMLInputElement> | CustomInputChangeEvent) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    //@ts-ignore
    form[name] = value

    onFormChange(form)
  }

  return (
    <Section>
      <SectionHeader isCloseable initialState={initialState} onToggle={onToggle}>
        <SectionHeaderTypography>Order Options</SectionHeaderTypography></SectionHeader>
      <SectionContent close={!initialState}>

        <StrucRow>
          <StrucCol size={4.5}>
            <InputOrderOptionsEditor
              disabled={disabled}
              initialState={false}
              inputLabel='Terms of "Delivery before 12:00 PM with GLS"'
              nameOfOption={nameOf<OrderOptionsForm>(lf => lf.orderOptionsTermsOfDelivery)}
              onFormChange={onOptionsChange}
              value={form.orderOptionsTermsOfDelivery} />
          </StrucCol>
          <StrucCol size={3} offset={0.5}>
            <InputWrapper>
              <InputLabel>Cost of "Delivery before 12:00 PM with GLS"</InputLabel>
              <Stepper allowDecimal={true} onValueChange={onDeliveryChange} min={0} value={form.orderOptionsDeliveryCost ?? 0} disabled={disabled} />
            </InputWrapper>
          </StrucCol>
        </StrucRow>

        <StrucRow>
          <StrucCol size={4.5}>
            <InputOrderOptionsEditor
              disabled={disabled}
              initialState={false}
              inputLabel='Terms of "Standard bag packaging"'
              nameOfOption={nameOf<OrderOptionsForm>(lf => lf.orderOptionsTermsOfStandardPackaging)}
              onFormChange={onOptionsChange}
              value={form.orderOptionsTermsOfStandardPackaging} />
          </StrucCol>
          <StrucCol size={3} offset={0.5}>
            <InputWrapper>
              <InputLabel>Cost of "Standard bag packaging"</InputLabel>
              <Stepper allowDecimal={true} onValueChange={onStandardPackagingChange} min={0} value={form.orderOptionsStandardPackagingCost ?? 0} disabled={disabled} />
            </InputWrapper>
          </StrucCol>
        </StrucRow>

        <StrucRow>
          <StrucCol size={4.5}>
            <InputOrderOptionsEditor
              disabled={disabled}
              initialState={false}
              inputLabel='Terms of "Specific packaging"'
              nameOfOption={nameOf<OrderOptionsForm>(lf => lf.orderOptionsTermsOfSpecificPackaging)}
              onFormChange={onOptionsChange}
              value={form.orderOptionsTermsOfSpecificPackaging} />
          </StrucCol>
          <StrucCol size={3} offset={0.5}>
            <InputWrapper>
              <InputLabel>Cost of "Specific packaging"</InputLabel>
              <Stepper allowDecimal={true} onValueChange={onSpecificPackagingChange} min={0} value={form.orderOptionsSpecificPackagingCost ?? 0} disabled={disabled} />
            </InputWrapper>
          </StrucCol>
        </StrucRow>

      </SectionContent>
    </Section>)
}
