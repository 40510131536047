import { JSX, RefObject } from 'react'
import { JetSportBox } from '../../../components/BoxView/JetSportBox'
import { BoxContent } from '../../../components/BoxView/BoxContent'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { ListReset } from './LogoPicker'
import LogoEditorTool from './LogoEditorTool'
import { isAllowed } from '../../../shared/functions/isAllowed'
import { Role } from '../../../../core/models/entities/Role'
import { UploadLogo } from './UploadLogo'
import LoadingElement from '../../../components/loading-element/loading-element'
import { LogoOptions } from '../../../../core/models/entities/LogoOptions'
import { Organization } from '../../../../core/models/entities/Organization'
import { BaseLogo } from '../../../../core/models/entities/BaseLogo'
import { ColorChange } from '../../logo-list/hooks/useLogoActionsHandler'
import { Profile } from '../../../../core/models/entities/Profile'
import { ImageLogoForm } from '../models/ImageLogoForm'


export interface LogoEditor_VM {
  isLoading: boolean
  isToolLoading: boolean
  logoListRef: RefObject<ListReset>
  options: LogoOptions
  selectedCustomer?: Organization
  filters: JSX.Element[]
  onFileUploadUpload: (image: File) => void
  onSelectLogo: (logo: BaseLogo) => void
  selectedLogo: BaseLogo | undefined
  tempColorImage: string | null
  onToolCancel: () => void
  onToolSaveDraftChanges?: () => void
  onToolSaveApprovalChanges: () => void
  onToolUpdateColor: (colors: ColorChange[]) => void
  profile: Profile,
  approvalDialog?: JSX.Element
  confirmationDialog?: JSX.Element
  customers: Organization[],
  logoForm: ImageLogoForm,
  updateForm: (form: ImageLogoForm) => void
  onResize: () => void
}

export function LogoEditorComponent(
  {
    isLoading,
    isToolLoading,
    logoListRef,
    options,
    selectedCustomer,
    filters,
    onFileUploadUpload,
    onSelectLogo,
    selectedLogo,
    tempColorImage,
    onToolCancel,
    onToolSaveDraftChanges,
    onToolSaveApprovalChanges,
    onToolUpdateColor,
    profile,
    approvalDialog,
    confirmationDialog,
    customers,
    logoForm,
    updateForm,
    onResize
  }: LogoEditor_VM): JSX.Element {
    
  return (<>
    {approvalDialog}
    {confirmationDialog}
    <JetSportBox className={'relative'}>
      <BoxContent className={'px-0'}>
        <StrucRow className={'w-full flex-1'}>
          <StrucCol size={12} className={'relative'}>
            {
              selectedLogo
                ? <LogoEditorTool tempColorImage={tempColorImage}
                  onCancel={onToolCancel}
                  onDraft={onToolSaveDraftChanges}
                  onCommit={onToolSaveApprovalChanges}
                  onUpdateColor={onToolUpdateColor} logo={selectedLogo} options={options}
                  isLoading={isToolLoading}
                  showCustomerInput={isAllowed(profile.userOrganizationInformations.role, Role.Vendor)}
                  customers={customers}
                  logoForm={logoForm}
                  updateForm={updateForm}
                  onResize={onResize}
                />
                : <UploadLogo onUploadImage={onFileUploadUpload} options={options} />
            }
            {isToolLoading && <LoadingElement transparent className={'rounded'} />}
          </StrucCol>
        </StrucRow>
      </BoxContent>
      {isLoading && <LoadingElement transparent showProgressBar className={'rounded'} />}
    </JetSportBox>
  </>
  )
}