import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton';
import { Dialog_VM, DialogFrame } from '../../../components/Dialogs/dialog-base';
import { CheckboxOptions } from '../../../components/Input/CheckboxOptions';
import LoadingElement from '../../../components/loading-element/loading-element';

interface OrderDialogProps {
    dialogVm: Dialog_VM
    toggle: boolean
    onSubmit: () => void
    onToggle: () => void
    onCancel?: () => void
    loading: boolean
}

interface DeliveyPackagingDialogProps extends OrderDialogProps {
    title: string
    termsCheckText?: string
    termsText: string
    explanation: string
    boldPrice?: string
}

export function OrderDialog({
    onSubmit,
    toggle,
    onToggle,
    onCancel,
    loading,
    dialogVm,
    title,
    termsText,
    termsCheckText,
    explanation,
    boldPrice
}: DeliveyPackagingDialogProps) {
    return <DialogFrame dialogOption={dialogVm}>
        {loading && <LoadingElement transparent />}
        <div className={'flex flex-col space-y-4 p-4 max-w-xl'}>
            <p className={'text-left text-base font-bold'}>{title}</p>
            <p className={'text-left'}>{termsText}</p>
            <p className={'text-left'}>
                {explanation}
                {boldPrice && <span className='font-bold'>{boldPrice}.</span>}
            </p>
            <div className={'flex flex-col justify-center space-y-4'}>
                <CheckboxOptions
                    header={''}
                    name={"checkbox"}
                    checked={toggle}
                    onChange={onToggle}
                    explain={termsCheckText ?? "I agree to the terms and wish to include the additional charge to my order."}
                    disabled={false}
                    checkboxPosition='left'
                ></CheckboxOptions>
            </div>
            <div className={'flex flex-col justify-center space-y-4'}>
                <div className="flex flex-row justify-between space-x-10">
                    <SecondaryButton className={'rounded-l-none font-bold w-60'} onClick={onCancel}>Close</SecondaryButton>
                    <PrimaryButton className="font-bold w-60" onClick={onSubmit} disabled={!toggle}>Accept</PrimaryButton>
                </div>
            </div>
        </div>
    </DialogFrame>
}
