import { useParams } from 'react-router-dom'
import { ComponentState, useComponentStateMachine } from '../../shared/hooks/useComponentStateMachine'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import LoadingElement from '../../components/loading-element/loading-element'
import { ErrorView } from '../../components/error-view/ErrorView'
import { OrganizationDetailsViewVendorWrapper } from './vmHooks/organization-details-view-vendor.wrapper'

export function VendorDetailsScreen() {
  const params = useParams()
  const id = params?.vendorId
  return <VendorDetailsScreenWithParam vendorId={+id!} />
}

interface Props {
  vendorId: number
}

export function VendorDetailsScreenWithParam({ vendorId }: Props) {

  const { state, dataTransition } = useComponentStateMachine()
  const { organizationRepository, userRepository } = useRepositoriesContext()
  const {
    data: vendor,
    isError: isVendorError,
    isLoading: isVendorLoading,
    isSuccess: isVendorSuccess
  } = organizationRepository.useVendor(vendorId)

  const {
    data: users,
    isError: isUsersError,
    isLoading: isUsersLoading,
    isSuccess: isUsersSuccess
  } = userRepository.useUsersOfOrganization(vendorId)

  const isLoading = isVendorLoading || isUsersLoading
  const isSuccess = isVendorSuccess && isUsersSuccess
  const isError = isVendorError || isUsersError

  dataTransition(isLoading, isSuccess, isError)

  return state === ComponentState.Succeed
    ? <OrganizationDetailsViewVendorWrapper organization={vendor!} users={users!} />
    : state === ComponentState.Loading
      ? <div className={'w-220 h-128 relative'}>
        <LoadingElement />
      </div>
      : <div className={'w-220 h-128 relative'}>
        <ErrorView />
      </div>
}

