import { nameOf } from "../../../../utils/functions/ReflectionUtil"
import { CustomInputChangeEvent } from "../../../../utils/models/events/CustomInputChangeEvent"
import { useDeliveryBeforeOrderDialog } from "../dialogs/delivery-before-order-dialog"
import { CountryAutoComplete, CountryAutoCompleteItem } from "../../../components/Input/CountryAutoComplete"
import { CustomDateInput, CustomInput, CustomTextArea } from "../../../components/Input/CustomInput"
import { InputLabel } from "../../../components/Input/InputLabel"
import { InputWrapper } from "../../../components/Input/InputWrapper"
import { DeliveryTypeOption, OrderDeliveryForm, PackagingOption } from "../useOrderConfirmationEditViewModel"
import { useDeliveryTypeOrderDialog } from "../dialogs/delivery-type-order-dialog"
import { useDeliveryPackagingOrderDialog } from "../dialogs/delivery-packaging-order-dialog"
import { Order, DeliveryType, Packaging } from "../../../../core/models/entities/Order"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons"

interface OrderFormProps {
  order: Order
  form: OrderDeliveryForm
  onFormChange: (form: OrderDeliveryForm) => void
  onDeliveryOrPackagingChange?: (deliveryType: DeliveryType, packaging: Packaging) => void
  deliveryTypeOptionList: DeliveryTypeOption[]
  packagingOptionList: PackagingOption[]
  readonly?: boolean
}

export function OrderForm({ form, order, onFormChange, onDeliveryOrPackagingChange, deliveryTypeOptionList, packagingOptionList, readonly }: OrderFormProps) {

  const [deliveryType, setDeliveryType] = useState<DeliveryType>(form.deliveryType)
  const [packagingType, setPackagingType] = useState<Packaging>(form.packaging)

  function handleFormChange(event: React.ChangeEvent<HTMLInputElement> | CustomInputChangeEvent) {
    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.type === 'date'
        ? new Date(target.value)
        : target.value;
    const name = target.name;
    //@ts-ignore
    form[name] = value;

    onFormChange({ ...form })
  }

  function onCountryChange(country: CountryAutoCompleteItem): void {
    onFormChange({ ...form, shipToCountry: country.code })
  }

  const deliveryGroup = deliveryTypeOptionList.map(x =>
    <div key={x.key} className="flex flex-row items-center pb-4 text-xs" onClick={() => onDeliveryTypeChange(x.type)}>
      {form.deliveryType === x.type
        ? <FontAwesomeIcon icon={faCircleCheck} className='text-status-success text-sm' />
        : <FontAwesomeIcon icon={faCircle} className='text-white text-sm border rounded-full' />}
      <span className="pl-2">{x.label}</span>
    </div>
  )

  const packagingGroup = packagingOptionList.map(x =>
    <div key={x.key} className="flex flex-row items-center pb-4 text-xs" onClick={() => onPackagingChange(x.type)}>
      {form.packaging === x.type
        ? <FontAwesomeIcon icon={faCircleCheck} className='text-status-success text-sm' />
        : <FontAwesomeIcon icon={faCircle} className='text-white text-sm border rounded-full' />}
      <span className="pl-2">{x.label}</span>
    </div>
  )

  const changeDeliveryOrPackaging = (
    delivery: DeliveryType = deliveryType,
    packaging: Packaging = packagingType
  ) => onDeliveryOrPackagingChange?.call(null, delivery, packaging);

  const { component: deliveryBeforeComponent, open: deliveryBeforeOpen } = useDeliveryBeforeOrderDialog({
    onContinue: async () => {
      onFormChange({ ...form, deliveryBefore12PMwithGLS: true })
    },
    onCancel: () => { }
  })

  const { component: deliveryTypeComponent, open: deliveryTypeOpen } = useDeliveryTypeOrderDialog({
    onContinue: async () => {
      onFormChange({ ...form, deliveryType: deliveryType })
      changeDeliveryOrPackaging(deliveryType, packagingType)
    },
    onCancel: () => { },
    type: deliveryTypeOptionList.find(x => x.key === deliveryType)!
  })

  const { component: packagingComponent, open: packagingOpen } = useDeliveryPackagingOrderDialog({
    onContinue: async () => {
      onFormChange({ ...form, packaging: packagingType })
      changeDeliveryOrPackaging(deliveryType, packagingType)
    },
    onCancel: () => { },
    packaging: packagingOptionList.find(x => x.key === packagingType)!
  })

  function onDeliveryBeforeChange(state: boolean) {
    if (!form.deliveryBefore12PMwithGLS && state) {
      deliveryBeforeOpen();
    }
    else {
      onFormChange({ ...form, deliveryBefore12PMwithGLS: false })
    }
  }

  function onDeliveryTypeChange(delivery: DeliveryType) {
    setDeliveryType(delivery);
    if (delivery == DeliveryType.Standard) {
      onFormChange({ ...form, deliveryType: delivery })
      changeDeliveryOrPackaging(delivery, packagingType)
    }
    else deliveryTypeOpen();
  }

  function onPackagingChange(packaging: Packaging) {
    setPackagingType(packaging);
    if (packaging == Packaging.NoPackaging) {
      onFormChange({ ...form, packaging: packaging })
      changeDeliveryOrPackaging(deliveryType, packaging)
    }
    else packagingOpen();
  }

  return <>
    {deliveryBeforeComponent}
    {deliveryTypeComponent}
    {packagingComponent}
    <div className={'flex bg-[#F8FAFD] p-6'}>
      <div>
        <div className="flex flex-col">
          <div className="py-2 font-bold block text-xs">
            <span>Delivery</span>
          </div>
          <div className="grid grid-cols-3">
            <div className="pr-4 max-w-48">
              <InputWrapper>
                <InputLabel>Company</InputLabel>
                <CustomInput
                  name={nameOf<OrderDeliveryForm>(lf => lf.shipToName)}
                  value={form.shipToName}
                  onChange={x => handleFormChange(x)}
                  style={{ backgroundColor: "#FFFFFF", borderColor: "#ECEEF9" }}
                  disabled={readonly}
                />
              </InputWrapper>
            </div>
            <div className="pr-4 max-w-48">
              <InputWrapper>
                <InputLabel>Contact Person</InputLabel>
                <CustomInput
                  name={nameOf<OrderDeliveryForm>(lf => lf.shipToContactPerson)}
                  value={form.shipToContactPerson}
                  onChange={x => handleFormChange(x)}
                  style={{ backgroundColor: "#FFFFFF", borderColor: "#ECEEF9" }}
                  disabled={readonly}
                />
              </InputWrapper>
            </div>
            <div className="pr-4 max-w-48">
              <InputWrapper>
                <InputLabel>Address</InputLabel>
                <CustomInput
                  name={nameOf<OrderDeliveryForm>(lf => lf.shipToAddress)}
                  value={form.shipToAddress}
                  onChange={x => handleFormChange(x)}
                  style={{ backgroundColor: "#FFFFFF", borderColor: "#ECEEF9" }}
                  disabled={readonly}
                />
              </InputWrapper>
            </div>
            <div className="pr-4 max-w-48">
              <InputWrapper>
                <InputLabel>Zip Code</InputLabel>
                <CustomInput
                  name={nameOf<OrderDeliveryForm>(lf => lf.shipToZip)}
                  value={form.shipToZip}
                  onChange={x => handleFormChange(x)}
                  style={{ backgroundColor: "#FFFFFF", borderColor: "#ECEEF9" }}
                  disabled={readonly}
                />
              </InputWrapper>
            </div>
            <div className="pr-4 max-w-48">
              <InputWrapper>
                <InputLabel>City</InputLabel>
                <CustomInput
                  name={nameOf<OrderDeliveryForm>(lf => lf.shipToCity)}
                  value={form.shipToCity}
                  onChange={x => handleFormChange(x)}
                  style={{ backgroundColor: "#FFFFFF", borderColor: "#ECEEF9" }}
                  disabled={readonly}
                />
              </InputWrapper>
            </div>
            <div className="pr-4 max-w-48">
              <InputWrapper>
                <InputLabel>Country</InputLabel>
                <CountryAutoComplete disabled={readonly ?? false} selectedCode={form.shipToCountry} onCountryChange={onCountryChange} />
              </InputWrapper>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="py-2 font-bold block text-xs">
          <p>Latest Delivery Date</p>
        </div>
        <div className="pr-4 max-w-48">
          <InputWrapper>
            <InputLabel>Date</InputLabel>
            <CustomDateInput
              min={order.latestDelivery.toISOString().slice(0, 10)}
              name={nameOf<OrderDeliveryForm>(lf => lf.latestDeliveryDate)}
              value={form.latestDeliveryDate.toISOString().slice(0, 10)}
              onChange={x => handleFormChange(x)}
              disabled={readonly ?? false}
              style={{ backgroundColor: "#FFFFFF", borderColor: "#ECEEF9" }}
            />
          </InputWrapper>
        </div>
        <div className="pr-4 max-w-48">
          <div className="flex flex-row items-center pb-4 text-xs" onClick={() => onDeliveryBeforeChange(!form.deliveryBefore12PMwithGLS)}>
            {form.deliveryBefore12PMwithGLS
              ? <FontAwesomeIcon icon={faCircleCheck} className='text-status-success text-sm' />
              : <FontAwesomeIcon icon={faCircle} className='text-white text-sm border rounded-full' />}
            <span className="pl-2">Delivery before 12:00 PM with GLS</span>
          </div>

        </div>
      </div>
      <div className="w-32">
        <div className="py-2 font-bold text-xs block">
          <p>Delivery Type</p>
        </div>
        <div className="grid grid-cols-1 pr-4">
          <fieldset id={nameOf<OrderDeliveryForm>(lf => lf.deliveryType)}>
            {deliveryGroup}
          </fieldset>
        </div>
      </div>
      <div className="w-44">
        <div className="py-2 font-bold text-xs block">
          <p>Packaging</p>
        </div>
        <div className="grid grid-cols-1">
          <fieldset id={nameOf<OrderDeliveryForm>(lf => lf.packaging)}>
            {packagingGroup}
          </fieldset>
        </div>
      </div>
      <div>
        <div className="py-2 font-bold text-xs block">
          <p>Order Comments</p>
        </div>
        <div className="">
          <InputWrapper>
            <InputLabel>Comments</InputLabel>
            <CustomTextArea
              name={nameOf<OrderDeliveryForm>(lf => lf.orderComment)}
              value={form.orderComment}
              onChange={x => handleFormChange(x)}
              disabled={readonly}
              style={{ backgroundColor: "#FFFFFF", borderColor: "#ECEEF9" }}
              className="w-48"
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Where are the clothes sent from?</InputLabel>
            <CustomInput
              name={nameOf<OrderDeliveryForm>(lf => lf.originOfDelivery)}
              value={form.originOfDelivery}
              onChange={x => handleFormChange(x)}
              disabled={readonly}
              style={{ backgroundColor: "#FFFFFF", borderColor: "#ECEEF9" }} />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Requisition Number</InputLabel>
            <CustomInput
              name={nameOf<OrderDeliveryForm>(lf => lf.requisitionNumber)}
              value={form.requisitionNumber}
              onChange={x => handleFormChange(x)}
              disabled={readonly}
              style={{ backgroundColor: "#FFFFFF", borderColor: "#ECEEF9" }} />
          </InputWrapper>
        </div>
      </div>
    </div>
  </>
}
