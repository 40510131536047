import { Organization } from './Organization'
import { TransferMethod } from './LogoOptions'
import { Approval, ApprovalGroup, ApprovalStatus } from './Approval'
import { Price } from './Price'
import { NotImplemented } from '../../../presentation/shared/functions/not-implemented.function'
import { AdditionalFee } from './Order'

export interface BaseLogo {
  inStorage: number
  title: string
  displayImage: ImageRef
  heightMm: number
  widthMm: number
  transferMethod: TransferMethod
  customer: Organization
  type: string
  id: number
  createdAtUtc: Date
  updateAtUtc?: Date
  createdBy: string
  isDeleted: boolean
  deletedAtUtc?: Date
  product?: ProductRef
  logoType: LogoType
  approval?: ApprovalGroup
  designCount: number
}

export function GetOverallApprovalStatus(approval: ApprovalGroup): ApprovalStatus {
  function isState(approval: Approval, state: ApprovalStatus): boolean {

    return approval.status === state
  }

  const rejected = ApprovalStatus.Rejected
  const approved = ApprovalStatus.Approved

  if (
    isState(approval.vendor, rejected) ||
    isState(approval.customer, rejected) ||
    (approval.external ? isState(approval.external!, rejected) : false)
  ) {
    return ApprovalStatus.Rejected
  }

  if (
    isState(approval.vendor, approved) &&
    isState(approval.customer, approved) &&
    (approval.external ? isState(approval.external!, approved) : true)
  ) {
    return ApprovalStatus.Approved
  }

  return ApprovalStatus.Pending;
}

export enum LogoType {
  Unknown = 'Unknown',
  TextLogo = 'TextLogo',
  NameLogo = 'NameLogo',
  NumberLogo = 'NumberLogo',
  ImageLogo = 'ImageLogo',
  SymbolLogo = 'SymbolLogo'
}

export interface TextLogo extends BaseLogo {
  value: string;
  font: string;
  color: string;
}

export interface NameLogo extends BaseLogo {
  font: string;
  color: string;
}

export interface NumberLogo extends BaseLogo {
  font: string;
  color: string;
}

export interface ImageLogo extends BaseLogo {
  isDigital: boolean;
  isChangeColor: boolean;
  status: LogoEditStatus;
  colorChange?: TemporaryColorChange;
  colors: string[];
  originalFileName: string;
}

export interface SymbolLogo extends BaseLogo {
  symbolKey: string;
  color: string;
}

export interface TemporaryColorChange {
  colorChangeCode: string;
  startedAtUtc: Date;
  ttlSec: number
}

export enum LogoEditStatus {
  Unknown = 'unknown',
  Draft = 'draft',
  Rejected = 'rejected',
  Validating = 'validating',
  Done = 'done'
}

export interface ImageRef {
  lowResUrl: string;
  highResUrl: string;
  originalUrl: string;
}

export interface ProductRef {
  sku: string;
  prices: ProductPrice[];
  additionalFees: AdditionalFee[]
}

export interface ProductPrice {
  type: string;
  currency?: string;
  startDate?: string;
  endDate?: string;
  minQuantity: number;
  adjustedPrice: number;
  adjustedUnitPrice: number;
}

export function isDigital(logo: BaseLogo): boolean {
  if (logo.logoType === LogoType.ImageLogo) {
    return (logo as ImageLogo).isDigital
  }
  return false;
}

export function isChangeColor(logo: BaseLogo): boolean {
  if (logo.logoType === LogoType.ImageLogo) {
    return (logo as ImageLogo).isChangeColor
  }
  return false;
}

export type LogoVariant = 'name' | 'number' | 'static'
export type PlaceholderLogoVariant = 'name' | 'number'
export type DeliveryVariant = 'Application' | 'Delivery' | 'Storage'

export function getLogoType(logo: BaseLogo): LogoVariant {
  switch (logo.logoType) {
    case LogoType.Unknown:
      console.warn('Unknown logo type detected')
      NotImplemented("Unknown logo type detected")
      return 'static'
    case LogoType.TextLogo:
      return 'static'
    case LogoType.NameLogo:
      return 'name'
    case LogoType.NumberLogo:
      return 'number'
    case LogoType.ImageLogo:
      return 'static'
    case LogoType.SymbolLogo:
      return 'static'
  }
}

export function isPlaceholder(logo: BaseLogo): boolean {
  const type = getLogoType(logo)
  return type === 'name' || type === 'number'
}

export function getTypeText(logo: BaseLogo): string {
  switch (logo.logoType) {
    case LogoType.Unknown: return "Unknown"
    case LogoType.TextLogo: return "Transfer"
    case LogoType.NameLogo: return "Transfer" 
    case LogoType.NumberLogo: return "Transfer" 
    case LogoType.ImageLogo: return "Transfer"
    case LogoType.SymbolLogo: return "Transfer"
  }
}

export function statusToString(status: LogoEditStatus): string {
  switch (status) {
    case LogoEditStatus.Unknown:
      return 'Unknown'
    case LogoEditStatus.Draft:
      return 'Draft'
    case LogoEditStatus.Rejected:
      return 'Rejected'
    case LogoEditStatus.Validating:
      return 'Validating'
    case LogoEditStatus.Done:
      return 'Done'
    default:
      console.warn('unknown status', status)
      return 'Unknown'
  }
}

export function getColors(logo: BaseLogo): string[] {
  switch (logo.logoType) {
    case LogoType.Unknown:
      console.warn('Unknown logo type detected')
      return [];
    case LogoType.TextLogo:
      return [(logo as TextLogo).color]
    case LogoType.NameLogo:
      return [(logo as NameLogo).color]
    case LogoType.NumberLogo:
      return [(logo as NumberLogo).color]
    case LogoType.ImageLogo:
      return (logo as ImageLogo).colors
    case LogoType.SymbolLogo:
      return [(logo as SymbolLogo).color]
  }
}

export function getLogoState(logo: BaseLogo): LogoState {

  const imageLogo = logo as ImageLogo

  if (imageLogo.status === LogoEditStatus.Draft) {
    return LogoState.Draft
  }

  if (imageLogo.status === LogoEditStatus.Rejected) {
    return LogoState.Rejected
  }

  if (imageLogo.status === LogoEditStatus.Done) {
    return LogoState.Approved
  }

  if (imageLogo.approval === undefined) {
    return LogoState.Undefined
  }

  switch (GetOverallApprovalStatus(imageLogo.approval!)) {
    case ApprovalStatus.Rejected:
      return LogoState.Rejected
    case ApprovalStatus.Approved:
      return LogoState.Approved
    case ApprovalStatus.Pending:
      return LogoState.Pending
  }
}

export enum LogoState {
  Draft = 'Draft',
  Rejected = 'Rejected',
  Pending = 'Pending',
  Approved = 'Approved',
  Undefined = 'Undefined',
}


export function getTransferDisplayName(logo: BaseLogo) {
  return (logo.transferMethod?.method1 ?? 'N/A') + (logo.transferMethod.method2 ? `, ${logo.transferMethod.method2}` : '')
}

export function getProductPrice(productPrices: ProductPrice[], quantity: number): ProductPrice | undefined {
  let highlightedMinQuantity: number | undefined = productPrices.sort(x => x.minQuantity).at(0)?.minQuantity ?? undefined;

  productPrices.forEach(p => {
    if (highlightedMinQuantity === undefined) {
      highlightedMinQuantity = p.minQuantity
    }
    if ((quantity) >= p.minQuantity) {
      if (highlightedMinQuantity == undefined) {
        highlightedMinQuantity = p.minQuantity
      }
      if (p.minQuantity > highlightedMinQuantity) {
        highlightedMinQuantity = p.minQuantity
      }
    }
  });

  return productPrices.find(x => x.minQuantity == highlightedMinQuantity);
}

export function GetProductMinQuantity(productPrices: ProductPrice[], quantity: number): number | undefined {
  let highlightedMinQuantity: number | undefined = undefined;
  productPrices.forEach(p => {
    if ((quantity) >= p.minQuantity) {
      if (highlightedMinQuantity == undefined) {
        highlightedMinQuantity = p.minQuantity
      }
      if (p.minQuantity > highlightedMinQuantity) {
        highlightedMinQuantity = p.minQuantity
      }
    }
  });

  return highlightedMinQuantity;
}

export function getDesignProductPrice(productPrices: Price[], quantity: number): Price | undefined {
  let highlightedMinQuantity: number | undefined = undefined;
  productPrices.forEach(p => {
    if ((quantity) >= p.minQuantity) {
      if (highlightedMinQuantity == undefined) {
        highlightedMinQuantity = p.minQuantity
      }
      if (p.minQuantity > highlightedMinQuantity) {
        highlightedMinQuantity = p.minQuantity
      }
    }
  });

  return productPrices.find(x => x.minQuantity == highlightedMinQuantity);
}
