import TableSearchBar from './TableSearchBar'
import '../../GenericTable.css'
import { SearchQueryEvent } from '../../Models/searchQueryEvent'
import { JSX, useEffect, useState } from 'react'
import { PageQuery } from '../../../../../utils/models/pageQuery'
import { BoxHeaderTypography } from '../../../Typographies/BoxHeaderTypography'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PrimaryButton } from '../../../Buttons/PrimaryButton'
import Dropdown from '../../../dropdown/Dropdown'
import { IdLookUps } from '../../../../../utils/models/idLookUp'
import { StrucCol } from '../../../StructureElements/StrucCol'
import { StrucRow } from '../../../StructureElements/StrucRow'
import { HStack } from '../../../../shared/components/VStack'
import { ReactComponent as FilterIcon } from '../../../../../resources/icons/filter-icon.svg'

export interface ListHeaderProps {
  tableTitle: string
  onSearchQueryChange: (nextSearchQuery: SearchQueryEvent) => void
  center: JSX.Element[] | JSX.Element
  right: JSX.Element[] | JSX.Element
  // buttons: JSX.Element[] | JSX.Element,
  filters?: FilterRecipe<unknown>[]
  additionalFilters?: FilterRecipe<unknown>[]
  query: PageQuery
  onFilterChange?: (event: FilterChangeEvent) => void
}

export interface FilterChangeEvent {
  activeFilters: { key: string; value: string | number }[]
}

let i = 0

function ListHeader({
  tableTitle,
  onSearchQueryChange,
  center,
  right,
  query,
  filters,
  additionalFilters,
  onFilterChange
}: ListHeaderProps) {
  const [openFilterBar, setOpenFilterBar] = useState<boolean>(false)
  let init: IdLookUps<FilterEvent['value']> = {}
  if (query.filterBy) {
    for (const filter of query?.filterBy) {
      init[filter.field] = filter.value
    }
  }

  const [activeFilters, setActiveFilters] = useState<IdLookUps<FilterEvent['value']>>(init)
  const [uncommittedFilter, setUncommittedFilter] =
    useState<IdLookUps<FilterEvent['value']>>(activeFilters)
  const [nActiveFilters, setNActiveFilters] = useState<number>(0)
  const [nUncommittedFilters, setNUncommittedFilters] = useState<number>(0)
  const [openFilterId, setOpenFilterId] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!onFilterChange) return
    const result = []
    for (const filterKey in activeFilters) {
      if (activeFilters[filterKey]) {
        result.push({
          key: filterKey,
          value: activeFilters[filterKey]!
        })
      }
    }
    setNActiveFilters(result.length)
    onFilterChange({ activeFilters: result })
  }, [activeFilters])

  useEffect(() => {
    if (!onFilterChange) {
      return
    }
    const result = []
    for (const filterKey in uncommittedFilter) {
      if (uncommittedFilter[filterKey]) {
        result.push({
          key: filterKey,
          value: uncommittedFilter[filterKey]!
        })
      }
    }
    setNUncommittedFilters(result.length)
  }, [uncommittedFilter])

  function handleFilterChange({ key, value }: FilterEvent) {
    setUncommittedFilter((af) => ({ ...af, [key]: value }))
  }

  function handleResetFilter() {
    setUncommittedFilter({})
  }

  function handleApplyFilters() {
    setActiveFilters(uncommittedFilter)
    setOpenFilterBar(false)
  }

  function handleToggleFilters() {
    setOpenFilterId(undefined)

    const newState = !openFilterBar
    if (!newState) {
      setUncommittedFilter(activeFilters)
    }
    setOpenFilterBar(newState)
  }

  return (
    <StrucRow className={'relative'}>
      <HStack className="w-full gap-7">
        <BoxHeaderTypography>{tableTitle}</BoxHeaderTypography>
        <div className="flex-1" />
        {filters && (
          <FilterMenuButton activeFilters={nActiveFilters} onFilterClick={handleToggleFilters} />
        )}
        {center}
        <div className="flex-1">
          <TableSearchBar
            searchQuery={query.searchTerm}
            onSearchQueryChange={onSearchQueryChange}
          />
        </div>
        {right}
      </HStack>
      {filters && openFilterBar && (
        <StrucRow
          style={{ width: '-webkit-fill-available' }}
          className={
            'flex-1 fill mx-[-32px] bg-white pr-8 pl-8 border-b space-y-2 z-10'
          }>
          <button className={'italic text-xs'} onClick={handleResetFilter}>
            Reset filters
          </button>
          <StrucRow className={'pb-4'}>
            <StrucCol size={11}>
              <StrucRow className={'space-x-2'}>{filters?.map((x) => (
                <Filter
                  open={openFilterId == x.key}
                  key={x.key}
                  filterKey={x.key}
                  onOptionSelect={handleFilterChange}
                  initialValue={uncommittedFilter[x.key]}
                  options={x.options}
                  getOptionDisplay={x.getOptionDisplay}
                  defaultTitle={x.defaultTitle}
                  getOptionValue={x.getOptionValue}
                  onOpenToggle={(open) => setOpenFilterId(open ? x.key : undefined)}
                />
              ))}
              </StrucRow>
              <StrucRow className={'mt-6 space-x-2'}>{additionalFilters?.map((x) => (
                <Filter
                  open={openFilterId == x.key}
                  key={x.key}
                  filterKey={x.key}
                  onOptionSelect={handleFilterChange}
                  initialValue={uncommittedFilter[x.key]}
                  options={x.options}
                  getOptionDisplay={x.getOptionDisplay}
                  defaultTitle={x.defaultTitle}
                  getOptionValue={x.getOptionValue}
                  onOpenToggle={(open) => setOpenFilterId(open ? x.key : undefined)}
                />
              ))}</StrucRow>
            </StrucCol>
            <StrucCol size={1}>
              <StrucRow>
                <PrimaryButton
                  disabled={nUncommittedFilters === 0 && nActiveFilters === 0}
                  onClick={handleApplyFilters}>
                  {nUncommittedFilters === 0 && nActiveFilters === 0
                    ? 'Apply Filters'
                    : nUncommittedFilters === 0
                      ? 'Clear Filters'
                      : `Apply Filters (${nUncommittedFilters})`}
                </PrimaryButton>
              </StrucRow>
            </StrucCol>
          </StrucRow>
        </StrucRow>
      )}
    </StrucRow>
  )
}

interface FilterEvent {
  value: string | number | undefined
  key: string
}

export interface FilterRecipe<T> {
  key: string
  options: T[]
  getOptionValue: (option: T) => string | number
  getOptionDisplay?: (option: T) => string
  initialValue?: string | number
  defaultTitle: string
  overwrite?: (option: T) => string | number
}

interface FilterProp<T> {
  open?: boolean
  filterKey: string
  options: T[]
  initialValue?: string | number
  onOpenToggle: (open: boolean) => void
  onOptionSelect: (event: FilterEvent) => void
  getOptionValue: (option: T) => string | number
  getOptionDisplay?: (option: T) => string
  defaultTitle: string
}

function Filter<T>({
  open,
  options,
  onOpenToggle,
  getOptionValue,
  onOptionSelect,
  getOptionDisplay,
  initialValue,
  filterKey,
  defaultTitle
}: FilterProp<T>) {
  const option = options.find((x) => '' + getOptionValue(x) === '' + initialValue)

  function handleOptionSelect(data?: T) {
    onOptionSelect({ value: data ? getOptionValue(data) : undefined, key: filterKey })
  }

  return (
    <Dropdown
      selectedOption={option}
      onOptionSelect={handleOptionSelect}
      options={options}
      getOptionDisplay={getOptionDisplay ?? ((datum: T) => '' + getOptionValue(datum))}
      defaultTitle={defaultTitle}
      width={'small'}
      onOpenToggle={onOpenToggle}
      open={open}
    />
  )
}

export default ListHeader

function FilterMenuButton({
  onFilterClick,
  activeFilters
}: {
  onFilterClick: () => void
  activeFilters: number
}) {
  return (
    <HStack className={'cursor-pointer gap-2'} onClick={onFilterClick}>
      <label className="italic text-xxs cursor-pointer">{`Active filters (${activeFilters})`}</label>
      <button>
        <FilterIcon />
      </button>
    </HStack>
  )
}
