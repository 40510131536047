import { useEffect, useState } from 'react'
import { useWhiteLabelContext } from '../../../../WhiteLabelContext'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { DetailsForm } from '../../customer-create/components/organization-details-form.component'
import { EmailPreferenceForm } from '../subcomponents/email-preference-editor.component'
import { MyProfileViewModel } from '../my-profile.component'
import { OrderOptionsForm } from '../subcomponents/order-options-editor.component'
import { WhiteLabelConfigurationsForm } from '../subcomponents/white-label-editor.components'
import { Profile } from '../../../../core/models/entities/Profile'
import { Commission, CommissionProfile } from '../../../../core/models/entities/Commission'
import { useLogoOptionsContext } from '../../../../contexts/LogoOptionsContext'
import { useProfileContext } from '../../../../contexts/ProfileContext'

interface Props {
  profile: Profile
}

export function useMyAdminProfile({ profile }: Props): MyProfileViewModel {

  const { organizationRepository } = useRepositoriesContext()
  const [profileConfigurations, setProfileConfigurations] = useState<Profile>({ ...profile });
  const [transition, setTransition] = useState(false);
  const { refetch, updateProfile } = useProfileContext()
  const { t } = useTranslation()
  const { reset: resetWhiteLabel, updateWhiteLabelContext } = useWhiteLabelContext();
  const logoOptions = useLogoOptionsContext();

  useEffect(() => {
    updateWhiteLabelContext({ ...profileConfigurations?.whiteLabelConfigurations! });
  }, [profileConfigurations]);

  const sections = {
    details: { show: true, canEdit: false },
    invoices: { show: true, canEdit: false },
    commissions: { show: true, canEdit: true },
    whiteLabelling: { show: true, canEdit: true },
    orderOptions: { show: true, canEdit: true }
  };

  const initialDetailsForm: DetailsForm = {
    postCode: profile?.detailedConfigurations?.organizationPostCode ?? "",
    address: profile?.detailedConfigurations?.organizationAddress ?? "",
    city: profile?.detailedConfigurations?.organizationCity ?? "",
    country: profile?.detailedConfigurations?.organizationCountry ?? "",
    contactEmail: profile?.detailedConfigurations?.contactEmail ?? "",
    contactPerson: profile?.detailedConfigurations?.contactPerson ?? "",
    contactPhone: profile?.detailedConfigurations?.contactPhone ?? "",
    cvr: profile?.detailedConfigurations?.organizationCVR ?? "",
    email: profile?.detailedConfigurations?.organizationEmail ?? "",
    name: profile?.userOrganizationInformations?.organizationName ?? "",
    phone: profile?.detailedConfigurations?.organizationPhone ?? "",
  }

  const initialEmailPreferenceform: EmailPreferenceForm = {
    emailPreferenceNewDesign: profileConfigurations?.emailPreferences?.emailPreferenceNewDesign ?? false,
    emailPreferenceNewLogos: profileConfigurations?.emailPreferences?.emailPreferenceNewLogos ?? false,
    emailPreferenceNewOrder: profileConfigurations?.emailPreferences?.emailPreferenceNewOrder ?? false,
    emailPreferencePendingOrder: profileConfigurations?.emailPreferences?.emailPreferencePendingOrder ?? false,
    emailPreferenceOrder: profileConfigurations?.emailPreferences?.emailPreferenceOrder ?? false,
  }

  function updateLogo(profile: Profile) {
    setProfileConfigurations({
      ...profileConfigurations!, whiteLabelConfigurations: {
        ...profileConfigurations!.whiteLabelConfigurations,
        logoUrl: profile.whiteLabelConfigurations.logoUrl
      }
    });
  }

  function updateProviderLogoHandle(file: File): void {
    organizationRepository.updateProviderLogo(file).then(() => {
      refetch().then(p => {
        updateLogo(p)
        toast.success(t('toasts.logo_uploaded_successfully'))
      })
    }).catch(() => {
      toast.error(t('toasts.logo_uploaded_unsuccessfully'))
    })
  }

  function updateWhiteLabel(form: WhiteLabelConfigurationsForm) {
    setProfileConfigurations({
      ...profileConfigurations!, whiteLabelConfigurations: {
        ...profileConfigurations!.whiteLabelConfigurations,
        colorHex: form.colorHex,
        whiteLabelContactEmail: form.whiteLabelContactEmail,
        whiteLabelContactPhone: form.whiteLabelContactPhone,
        whiteLabelCopyrightName: form.whiteLabelCopyrightName,
        whiteLabelVendorName: form.whiteLabelVendorName,
        whiteLabelWebsiteLink: form.whiteLabelWebsiteLink
      }
    });
  }

  function updateDetails(form: DetailsForm) {
    setProfileConfigurations({
      ...profileConfigurations!, detailedConfigurations: {
        ...profileConfigurations!.detailedConfigurations,
        contactEmail: form.contactEmail,
        contactPerson: form.contactPerson,
        contactPhone: form.contactPhone,
        organizationAddress: form.address,
        organizationEmail: form.email,
        organizationCVR: form.cvr,
        organizationPhone: form.phone
      },
      userOrganizationInformations: {
        ...profileConfigurations!.userOrganizationInformations,
        organizationName: form.name
      }
    });
  }

  function updateOrderOptions(form: OrderOptionsForm) {
    setProfileConfigurations({
      ...profileConfigurations!, orderOptions: {
        ...profileConfigurations!.orderOptions,
        orderOptionsTermsOfDelivery: form.orderOptionsTermsOfDelivery,
        orderOptionsTermsOfStandardPackaging: form.orderOptionsTermsOfStandardPackaging,
        orderOptionsTermsOfSpecificPackaging: form.orderOptionsTermsOfSpecificPackaging,
        orderOptionsDeliveryCost: form.orderOptionsDeliveryCost ?? 0,
        orderOptionsStandardPackagingCost: form.orderOptionsStandardPackagingCost,
        orderOptionsSpecificPackagingCost: form.orderOptionsSpecificPackagingCost
      }
    });
  }

  function updateEmailPreferences(form: EmailPreferenceForm) {
    setProfileConfigurations({
      ...profileConfigurations!, emailPreferences: {
        ...profileConfigurations!.emailPreferences,
        emailPreferenceNewDesign: form.emailPreferenceNewDesign,
        emailPreferenceNewLogos: form.emailPreferenceNewLogos,
        emailPreferenceNewOrder: form.emailPreferenceNewOrder,
        emailPreferencePendingOrder: form.emailPreferencePendingOrder,
        emailPreferenceOrder: form.emailPreferenceOrder
      }
    });
  }

  function resetProfile() {
    setProfileConfigurations(profile);
    resetWhiteLabel();
  }

  function saveProfileHandle() {
    setTransition(true);
    updateProfile(profileConfigurations!).then(() => {
      refetch().then(() => {
        toast.success(t('toasts.profile_update_success'));
      })
    }).catch(() => {
      toast.error(t('toasts.profile_update_failed'));
    }).finally(() => setTransition(false));
  }

  return {
    resetProfile: resetProfile,
    saveProfileChanges: saveProfileHandle,
    updateEmailPreferences: updateEmailPreferences,
    updateLogoHandle: updateProviderLogoHandle,
    updateOrganizationDetails: updateDetails,
    updateWhiteLabel: updateWhiteLabel,
    updateOrderOptions: updateOrderOptions,
    sections: sections,
    profile: profileConfigurations!,
    loading: false,
    detailsForm: initialDetailsForm,
    emailPreferenceForm: initialEmailPreferenceform,
    transition: transition,

    isVendor: true,
    vendorId: undefined,
    isAdmin: true,
    logoOptions: logoOptions,
    updateCommission: () => { return; },
    commissionProfile: {
      typeOnly: [] as Commission[],
      methods: [] as Commission[]
    } as CommissionProfile
  }
}
