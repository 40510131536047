import { JetSportBox } from '../../components/BoxView/JetSportBox'
import { InvertedPanel } from '../../components/BoxView/InvertedPanel'
import { OrderLineList } from '../order-details/subcomponents/OrderLineList'
import { SecondaryButton } from '../../components/Buttons/SecondaryButton'
import { PrimaryButton } from '../../components/Buttons/PrimaryButton'
import { PriceTotal } from '../order-details/subcomponents/PriceTotal'
import { BoxHeader2 } from '../../components/BoxView/BoxHeader2'
import { BoxContent } from '../../components/BoxView/BoxContent'
import { BoxFooter } from '../../components/BoxView/BoxFooter'
import { OrderForm } from './subcomponents/OrderDeliveryForm'
import { DeliveryTypeOption, OrderDeliveryForm, PackagingOption } from './useOrderConfirmationEditViewModel'
import { DeliveryType, Order, OrderLine, Packaging } from '../../../core/models/entities/Order'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { useEffect } from 'react'

export interface OrderDeliveryFormViewModel {
  order: Order,
  orderNo: string
  orderlines: OrderLine[]
  handleBack: () => void
  handleChange: () => void
  handleConfirm: () => void
  form: OrderDeliveryForm
  deliveryTypeGroupList: DeliveryTypeOption[]
  packagingGroupList: PackagingOption[]
  handleOrderFormUpdate: (form: OrderDeliveryForm) => void
  handlePackagingOrDeliveryChange?: (deliveryType: DeliveryType, packaging: Packaging) => void
  subTotal: number
  vat: number
  totalAmount: number
  isLoading: boolean
  readonly?: boolean
  isFormValid: boolean
}

export function OrderConfirmationComponent({ order, orderlines, orderNo, form, isLoading, handleOrderFormUpdate, handlePackagingOrDeliveryChange, handleBack, handleChange, handleConfirm, deliveryTypeGroupList, packagingGroupList, subTotal, vat, totalAmount, readonly, isFormValid }: OrderDeliveryFormViewModel) {
  useEffect(() => {
    if (order.deliveryType == null || order.packagingType == null)
      handlePackagingOrDeliveryChange?.call(null, form.deliveryType, form.packaging)
  }, [])

  if (isLoading) return <LoadingView />

  return <JetSportBox className={'min-h-[900px]'}>
    <BoxHeader2 className={'uppercase text-sm font-bold flex justify-between items-center'}>
      <p>Order confirmation - #{orderNo}</p>
    </BoxHeader2>
    <BoxContent>
      <InvertedPanel>
        Panel
      </InvertedPanel>
      <div className={'flex-1 flex-col my-10'}>
        <OrderLineList orderlines={orderlines} />
        <PriceTotal subTotal={order.subTotal} vat={order.vat} totalAmount={order.total} />
      </div>
      <OrderForm form={form} order={order} readonly={readonly} onFormChange={handleOrderFormUpdate} onDeliveryOrPackagingChange={handlePackagingOrDeliveryChange} deliveryTypeOptionList={deliveryTypeGroupList} packagingOptionList={packagingGroupList} />
    </BoxContent>
    <div className={'flex justify-between mx-8 mt-4'}>
      <SecondaryButton onClick={handleBack}>Back</SecondaryButton>
      <div className={'flex justify-between gap-x-8'}>
        <PrimaryButton disabled={readonly} onClick={handleChange}>Change</PrimaryButton>
        <PrimaryButton disabled={readonly || !isFormValid} onClick={handleConfirm}>Confirm</PrimaryButton>
      </div>
    </div>
    <BoxFooter className={'justify-between'}>
    </BoxFooter>
  </JetSportBox>
}
