import { JSX, useState } from 'react'
import { useDialog } from '../../../components/Dialogs/dialog-base'
import { DeliveryTypeOption } from '../useOrderConfirmationEditViewModel'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { OrderDialog } from './OrderDialog'
import { DeliveryType } from '../../../../core/models/entities/Order'

interface Props {
  component: JSX.Element
  open: () => void
}

export function useDeliveryTypeOrderDialog({
  onContinue,
  onCancel,
  type
}: { onContinue: () => Promise<void>, onCancel?: () => void, type: DeliveryTypeOption }): Props {

  const [loading, setLoading] = useState(false)
  const [toggle, setToggle] = useState<boolean>(false);

  const dialog = useDialog()
  const { profile } = useProfileContext()

  function handleSubmit() {
    setLoading(true);
    onContinue().finally(() => {
      setLoading(false)
      setToggle(false);
      dialog.closeDialog()
    })
  }

  function handleToggle() {
    setToggle(!toggle)
  }

  function handleCancel() {
    if (onCancel) onCancel()
    dialog.closeDialog()
  }

  // TODO Hardcoded percentage surcharge, which is DEFINITELY not the right way.
  const getDeliveryPercentageSurcharge = () => {
    switch (type.type) {
      case DeliveryType.Express24: return 100
      case DeliveryType.Express48: return 50
      case DeliveryType.Express72: return 25
      default: return 0
    }
  }

  const geExpressDeliveryType = () => {
    switch (type.type) {
      case DeliveryType.Express24: return "Express 24"
      case DeliveryType.Express48: return "Express 48"
      case DeliveryType.Express72: return "Express 72"
      default: return "standard delivery"
    }
  }

  const p = getDeliveryPercentageSurcharge()
  const e = geExpressDeliveryType()

  return {
    component: <>
      <OrderDialog
        dialogVm={dialog}
        onToggle={handleToggle}
        onSubmit={handleSubmit}
        loading={loading}
        onCancel={handleCancel}
        toggle={toggle}
        title={type.title}
        termsText=""
        explanation={`The current fee for ${e} is ${p}% of the order value, meaning an additional ${p}% will be added to the current order total.`}
      />
    </>,
    open: dialog.openDialog
  }
}
