import React, { JSX, useEffect, useState } from 'react'
import { BaseLogo, ImageLogo, LogoEditStatus, isChangeColor, isDigital } from '../../../../core/models/entities/BaseLogo'
import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '@mui/material/Tooltip'

import { PrintMethodAutoComplete } from './PrintMethodAutoComplete'
import { ImageLogoForm } from '../models/ImageLogoForm'
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { LogoOptions, TransferMethod } from '../../../../core/models/entities/LogoOptions'
import LoadingElement from '../../../components/loading-element/loading-element'
import { ColorChange } from '../../logo-list/hooks/useLogoActionsHandler'
import { LogoImage } from '../../../components/Logo/LogoImage'
import { useConfirmationDialog } from '../../../components/Dialogs/confirmation-dialog'
import DigitalColorBlock from './DigitalColorBlock'
import LogoColorChanger from './LogoColorChanger'
import { CustomAutoComplete } from '../../../components/Input/CustomAutoComplete'
import { Organization } from '../../../../core/models/entities/Organization'
import { Role } from '../../../../core/models/entities/Role'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { LogoResize } from './LogoResize'

export interface LogoEditorTool_VM {
  options?: LogoOptions
  logo: BaseLogo
  onCancel: () => void
  onDraft?: () => void
  onCommit: () => void
  onUpdateColor: (colors: ColorChange[]) => void
  tempColorImage: string | null
  isLoading: boolean
  showCustomerInput: boolean
  customers: Organization[]
  logoForm: ImageLogoForm
  updateForm: (form: ImageLogoForm) => void
  onResize: () => void
}

function LogoEditorTool(
  {
    options,
    logo: baseLogo,
    onDraft,
    onCommit,
    onCancel,
    onUpdateColor,
    isLoading,
    showCustomerInput,
    customers,
    logoForm,
    updateForm,
    onResize
  }: LogoEditorTool_VM): JSX.Element {
  // Queue the logo for saving as draft.
  // Some bug causes useEffect to run twice, which results in an infinite state-update loop.
  // Not fixing this bug now, so adding a state-trigger hack instead.
  const [queued, setQueued] = useState(false)

  useEffect(() => {
    if (queued) {
      onDraft?.call(null)
      setQueued(false)
    }
    // TODO Should be using `logoForm.transferMethod` as dependency instead, 
    //  but this causes the effect to run twice and loop indefinitely.
  }, [queued])

  const { role } = useProfileContext()
  const logo = baseLogo as ImageLogo

  function handleCommit() {
    confirmationDialog.open()
  }

  const confirmationDialog = useConfirmationDialog(
    'Are you sure you want save and commit this image?',
    onCommit,
  )

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    updateForm({
      ...logoForm,
      [name]: value
    })
  }

  function handleSizeChange(newWidth: number, newHeight: number) {
    updateForm({
      ...logoForm,
      widthMm: newWidth,
      heightMm: newHeight
    })
  }

  function onUpdateCustomer(customer?: Organization) {
    updateForm({
      ...logoForm,
      customer: customer ?? logoForm.customer
    })
  }

  function updateColorsHandler(colors: ColorChange[]) {
    onUpdateColor(colors)
  }

  function changePrintMethod(method: TransferMethod | null) {
    if (method) {
      const [maxWidth, maxHeight] = method.size.split('x').map(Number)
      updateForm({
        ...logoForm,
        transferMethod: method,
        heightMm: logoForm.heightMm > maxHeight ? maxHeight : logoForm.heightMm,
        widthMm: logoForm.widthMm > maxWidth ? maxWidth : logoForm.widthMm
      })
    }
    else {
      updateForm({
        ...logoForm,
        transferMethod: method,
      })
    }
    // Mark the logo as queued for saving as draft.
    setQueued(true)
  }

  if (!options) {
    return (<></>)
  }

  const filteredMethods = options.transferMethods.filter(x =>
    isDigital(logo)
      ? (x.method1 + x.method2 + x.method3).toLowerCase().includes("digital")
      : options.transferMethods
  )

  return (
    <>
      {confirmationDialog.component}
      {isLoading && <LoadingElement transparent />}
      <div className={'h-full flex flex-col'}>
        <div id={'toolbar'} className={'bg-gray-100 space-y-2 py-2 px-3 rounded-tr-md'}>
          <div className={'flex items-center space-x-3'}>
            <h2 className={'flex-1 font-bold text-gray-600'}>EDITING LOGO</h2>
            <SecondaryButton width={'md'} disabled={isLoading} onClick={onCancel}>Cancel</SecondaryButton>
            {logo.status === LogoEditStatus.Draft && !!onDraft && <PrimaryButton width={'md'} disabled={isLoading} onClick={onDraft}>Save Draft</PrimaryButton>}
            <PrimaryButton width={'md'} disabled={isLoading} onClick={handleCommit}>Save Changes And Approve</PrimaryButton>
          </div>
          <div className={'text-gray-600 flex items-center space-x-3 text-xs'}>
            {showCustomerInput && <div className={'flex items-center space-x-2'}>
              <label className={'uppercase'}>Customer</label>
              <CustomAutoComplete<Organization>
                options={customers}
                getOptionKey={(org) => org.id}
                displayOption={(org) => org.name}
                key={logoForm.customer?.id}
                onChange={(org) => onUpdateCustomer(org!)}
                value={logoForm.customer ?? null}
                placeholder='search for customer'
                disabled={role === Role.EndUser}
              />
            </div>}

            <div className={'flex items-center space-x-2'}>
              <label className={'uppercase'}>Title</label>
              <input className={'input'}
                disabled={isLoading}
                name={nameOf<ImageLogoForm>(lf => lf.title)}
                type='text'
                value={logoForm.title}
                onChange={handleInputChange}
                maxLength={30}
              />
            </div>
            {isDigital(logo) && <div className={'flex items-center space-x-2'}>
              <label className={'uppercase'}>Type</label>
              <button className={'but-32 p-1 border rounded flex items-center'}>
                <div className={'flex items-center h-5 w-5 mr-0.5'}><DigitalColorBlock /></div>
                Digital Transfer
              </button>
            </div>}
            <div className={'flex items-center space-x-2 flex-1'}>
              <label className={'uppercase'}>Method</label>
              <PrintMethodAutoComplete disabled={isLoading} value={logoForm.transferMethod?.key ?? ''}
                methods={filteredMethods} onChange={changePrintMethod} />
              <Tooltip title={<PrintMethodInfo />} placement={'bottom-start'}>
                <FontAwesomeIcon size={'lg'} icon={faCircleInfo} />
              </Tooltip>
            </div>
            <LogoResize width={logoForm.widthMm} height={logoForm.heightMm} onSizeChange={handleSizeChange} onResize={onResize} disabled={isLoading} />
          </div>
          {isChangeColor(logo) && <LogoColorChanger disabled={isLoading} options={options} logo={logo} onUpdateColor={updateColorsHandler} />}
        </div>
        <div className={'flex-1 flex justify-center items-center relative'}>
          {isDigital(logo) && <div className={'absolute top-0 left-0 p-2'}>
            <Tooltip placement={'right-start'} title={<IsDigitalInfo />}>
              <div>
                <FontAwesomeIcon className={'text-status-failed-1'} size={'lg'} icon={faCircleExclamation} />
              </div>
            </Tooltip>
          </div>}
          <LogoImage
            className={'min-h-[325px] min-w-[450px] max-h-[650px] max-w-[900px] object-scale-down bg-white rounded-bl'}
            logo={logo} useHighRes={true}
          />
        </div>
      </div>
    </>
  )
}

export default LogoEditorTool

function IsDigitalInfo() {

  return <div className={'text-gray-100'}>
    <p>Logo can only be created in Digital Transfer.</p>
    <br />
    <p>This can be because of the following:</p>
    <ul>
      <li>• Logo isn’t a vector file.</li>
      <li>• Logo contains a color-gradient.</li>
      <li>• Logo has more than 10 colors.</li>
      <li>• Logo isn’t 100% opacity.</li>
    </ul>
  </div>
}

function PrintMethodInfo() {
  return <div className={'text-gray-100 w-[160px] font-medium'}>
    <b>Standard</b> er den almindelige transfer til stoffet.<br /><br />
    <b>Industri</b> er den mere hårdføre transfer til stoffet, som forventes udsat for hårdt slid.<br /><br />
    <b>Vand Industri</b> er den hårdføre transfer til stoffet, som ønskes udstyret med et blødere, mere åndbart og
    bæredygtigt tryk.<br /><br />
    <b>Vand Refleks</b> giver tryghed og opmærksomhed fra omgivelserne under sparsomt belyste og risikobehæftede
    forhold.<br /><br />
    <b>Vand Sport</b> er transferen til meget strækbare stoffer som løbebukser, holdtrøjer og andet sports- og
    motionsudstyr.<br /><br />
    <b>ColorStop</b> understøtter stoffet med et anti-migrationslag, som minimerer risikoen for gennemblødning og
    misfarvning.<br /><br />
    <b>Digital transfer</b> er den ypperste eksponent for præcis afbildning af kreative, detaljerede og farvedybe
    designs.<br /><br />
    <b>Digital ColorStop</b> minimerer risikoen for gennemblødning og misfarvning for designs med naturtro billeder,
    farveforløb eller højt farveantal.<br /><br />
    <b>Nylon</b> sidder solidt på tøjet, mens nylonbeklædningen kæmper mod de våde elementer.<br /><br />
  </div>
}