import { User } from '../../../core/models/entities/User'
import { CreateUserDto } from '../../../core/models/dtos/CreateUserDto'
import { Dialog_VM, DialogFrame, useDialog } from '../Dialogs/dialog-base'
import { SubSection } from '../StructureElements/Section'
import { SectionHeader } from '../../screens/my-profile/SectionHeader'
import { PrimaryButton } from '../Buttons/PrimaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { SectionContent } from '../../screens/my-profile/SectionContent'
import React, { useEffect, useState } from 'react'
import { InputWrapper } from '../Input/InputWrapper'
import { InputLabel } from '../Input/InputLabel'
import { CustomInput } from '../Input/CustomInput'
import { nameOf } from '../../../utils/functions/ReflectionUtil'
import { SubSectionHeaderTypography } from '../Typographies/SubSectionHeaderTypography'
import { BoxHeaderTypography } from '../Typographies/BoxHeaderTypography'

export function UserList({ users, onCreate, onDelete }: {
  users: User[],
  onCreate?: (user: CreateUserDto) => Promise<any>,
  onDelete?: (userId: number) => Promise<void>
}) {
  const [userState, setUserState] = useState(users)

  useEffect(() => {
    setUserState(users)
  }, [users])

  const createUserDialog = useDialog()

  function handleCreateUser(user: CreateUserDto) {
    if (onCreate) onCreate(user).then(createUserDialog.closeDialog)
  }

  async function handleDeleteUser(user: User) {
    if (!onDelete) return
    if (confirm(`Are you sure you want to delete the user ${user.email}`)) {
      await onDelete(user.id)
      setUserState(userState.filter((u) => u.id != user.id))
    }
  }

  return <SubSection>
    <CreateUserDialog dialogOptions={createUserDialog} onCreate={handleCreateUser} />
    <SectionHeader isCloseable={false}>
      <div className={'flex justify-between items-center'}>
        <SubSectionHeaderTypography>Users</SubSectionHeaderTypography>
        <PrimaryButton width={'icon'} onClick={createUserDialog.openDialog}><FontAwesomeIcon
          icon={faPlus} /></PrimaryButton>
      </div>
    </SectionHeader>
    <SectionContent close={false}>
      <ul className={'border rounded h-96 flex-1 overflow-y-scroll'}>
        {userState.map(x =>
          <li key={x.id} className={'py-3 px-2'}>{x.email}
            <button style={{ float: 'right', cursor: 'pointer' }} onClick={() => handleDeleteUser(x)}>
              Delete
            </button>
          </li>)}
      </ul>
    </SectionContent>
  </SubSection>
}

interface CreateUserDialogProps {
  dialogOptions: Dialog_VM,
  onCreate: (user: CreateUserDto) => void
}

function CreateUserDialog({ dialogOptions, onCreate }: CreateUserDialogProps) {
  const [createUser, setCreateUser] = useState<CreateUserDto>({
    email: '',
    firstname: '',
    lastname: '',
    organizationId: 0
  })

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    setCreateUser(x => ({
      ...x,
      [name]: value
    }))
  }

  function handleSubmit() {
    onCreate(createUser)
  }

  return <DialogFrame dialogOption={dialogOptions}>
    <div className={'space-y-6'}>
      <BoxHeaderTypography>Create a new user</BoxHeaderTypography>
      <div className={'space-y-4'}>
        <InputWrapper>
          <InputLabel>First name</InputLabel>
          <CustomInput name={nameOf<CreateUserDto>(e => e.firstname)}
            value={createUser.firstname}
            onChange={e => handleInputChange(e)} />
        </InputWrapper>
        <InputWrapper>
          <InputLabel>Last name</InputLabel>
          <CustomInput name={nameOf<CreateUserDto>(e => e.lastname)} value={createUser.lastname}
            onChange={e => handleInputChange(e)} />
        </InputWrapper>
        <InputWrapper>
          <InputLabel>Email</InputLabel>
          <CustomInput name={nameOf<CreateUserDto>(e => e.email)} value={createUser.email}
            onChange={e => handleInputChange(e)} />
        </InputWrapper>
        <div className={'flex justify-center'}>
          <PrimaryButton width={'md'} onClick={handleSubmit}>Create</PrimaryButton>
        </div>
      </div>
    </div>
  </DialogFrame>
}