import {
  ImageRefDto,
  LogoDto,
  ProductRefDto,
  TemporaryColorChangeDto,
  TransferMethodDto
} from '../responses/LogoDto'
import {
  BaseLogo,
  ImageLogo,
  ImageRef,
  LogoType,
  NameLogo,
  NumberLogo,
  ProductPrice,
  ProductRef,
  SymbolLogo,
  TemporaryColorChange,
  TextLogo
} from '../../../core/models/entities/BaseLogo'
import { mapToOrganization } from './mapOrganization'
import { TransferMethod } from '../../../core/models/entities/LogoOptions'
import { mapToApprovalGroup } from './ApprovalMapper'
import { mapToAdditionalFee } from './mapOrder'
import { PriceDto } from '../responses/PriceDto'

function mapToImageRef(dto: ImageRefDto): ImageRef {
  return { ...dto, highResUrl: dto.highResUrl ?? dto.lowResUrl }
}

function mapToLogoType(logoType: string): LogoType {
  return LogoType[logoType as keyof typeof LogoType] ?? LogoType.Unknown
}

function mapToProductPrice(dto: PriceDto): ProductPrice {
  return { ...dto }
}

export function mapToProductRef(dto: ProductRefDto): ProductRef {
  return {
    ...dto,
    additionalFees: dto.additionalFees?.map(mapToAdditionalFee) ?? [],
    prices: dto.prices.map((p) => mapToProductPrice(p))
  }
}

function mapToTransferMethod(dto: TransferMethodDto): TransferMethod {
  return { ...dto }
}

function mapToBaseLogo(dto: LogoDto): BaseLogo {
  return {
    ...dto,
    displayImage: mapToImageRef(dto.displayImage),
    customer: mapToOrganization(dto.customer ?? dto.vendor),
    createdAtUtc: new Date(dto.createdAtUtc),
    updateAtUtc: dto.updateAtUtc ? new Date(dto.updateAtUtc) : undefined,
    deletedAtUtc: dto.deletedAtUtc ? new Date(dto.deletedAtUtc) : undefined,
    product: dto.product ? mapToProductRef(dto.product) : null,
    transferMethod: mapToTransferMethod(dto.transferMethod),
    approval: dto.approval ? mapToApprovalGroup(dto.approval) : undefined,
    inStorage: dto.inStorage,
    designCount: dto.designCount
  } as BaseLogo
}

function mapToNameLogo(dto: LogoDto): NameLogo {
  return {
    ...mapToBaseLogo(dto),
    font: dto.font,
    color: dto.color,
    logoType: LogoType.NameLogo
  }
}

function mapToNumberLogo(dto: LogoDto): NumberLogo {
  return {
    ...mapToBaseLogo(dto),
    font: dto.font,
    color: dto.color,
    logoType: LogoType.NumberLogo
  }
}

function mapToTemporaryColorChange(dto: TemporaryColorChangeDto): TemporaryColorChange {
  return { ...dto, startedAtUtc: new Date(dto.startedAtUtc) }
}

function mapToImageLogo(dto: LogoDto): ImageLogo {
  return {
    ...mapToBaseLogo(dto),
    isDigital: dto.isDigital,
    isChangeColor: dto.isChangeColor,
    status: dto.status,
    colorChange: dto.colorChange ? mapToTemporaryColorChange(dto.colorChange) : undefined,
    colors: dto.colors,
    approval: dto.approval ? mapToApprovalGroup(dto.approval) : undefined,
    originalFileName: dto.originalFileName,
    logoType: LogoType.ImageLogo
  }
}

function mapToSymbolLogo(dto: LogoDto): SymbolLogo {
  return {
    ...mapToBaseLogo(dto),
    symbolKey: dto.symbolKey,
    color: dto.color,
    logoType: LogoType.SymbolLogo
  }
}

function mapToTextLogo(dto: LogoDto): TextLogo {
  return {
    ...mapToBaseLogo(dto),
    value: dto.value,
    font: dto.font,
    color: dto.color,
    logoType: LogoType.TextLogo
  }
}

export function mapToLogo(dto: LogoDto): BaseLogo {
  const logotype = mapToLogoType(dto.logoType)

  switch (logotype) {
    default:
    case LogoType.Unknown:
      console.warn('unknown logo type:', logotype)
      return mapToBaseLogo(dto)
    case LogoType.TextLogo:
      return mapToTextLogo(dto)
    case LogoType.NameLogo:
      return mapToNameLogo(dto)
    case LogoType.NumberLogo:
      return mapToNumberLogo(dto)
    case LogoType.ImageLogo:
      return mapToImageLogo(dto)
    case LogoType.SymbolLogo:
      return mapToSymbolLogo(dto)
  }
}
