import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { Design, DesignState, getDesignState } from '../../../../core/models/entities/Design'
import { formatDate } from '../../../../utils/functions/DateUtil'
import IconButton from '../../../components/LogoButton/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { ColumnSettings } from '../../../components/GenericTable/SubComponents/ListFrame'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { DesignPrices } from '../../../components/Logo/LogoPrices'
import { DesignStatusElement } from '../../../components/Design/DesignStatusElement'
import { DesignImage } from '../../../components/Design/DesignImage'
import { Verdict } from '../../../components/ApprovalVoting/approval-voting'
import { Tooltip } from '@mui/material'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { Role } from '../../../../core/models/entities/Role'
import { DesignClickCallback } from '../vmHooks/useFunctionalListDesignViewModel'
import {
  AddToCartIcon,
  EditIcon,
  NoteIcon,
  TrashcanCrossIcon
} from '../../../../resources/icons/Icons'
import { Stepper } from '../../../components/Buttons/Stepper'
import { ApprovalStatus } from '../../../../core/models/entities/Approval'
import CustomerTooltip from '../../../components/Organization/CustomerTooltip'

interface DesignRowItem {
  design: Design
  columns: ColumnSettings
  onApprove: DesignClickCallback
  onReject: DesignClickCallback
  onPlaceVerdict: (design: Design, verdict: Verdict) => void
  onSeeRejectNote: (design: Design) => void
  onAddToCart: (design: Design, quantity: number) => void
  onEditDesign: (design: Design) => void
  onDeleteDesign: (design: Design) => void
  onInitiateApproval: (design: Design) => void
  onRejectAndDelete: (design: Design, note?: string) => Promise<void>
}

// TODO Deprecate
export function isDesignApprovedByMe(design: Design, role: Role) {
  switch (role) {
    case Role.Admin:
      return false
    case Role.Vendor:
      return design.approval?.vendor?.status === ApprovalStatus.Approved
    case Role.EndUser:
      return design.approval?.customer?.status === ApprovalStatus.Approved
  }
}

export function DesignRowItem({
  design,
  columns,
  onDeleteDesign,
  onEditDesign,
  onAddToCart,
  onReject,
  onApprove
}: DesignRowItem) {
  const { role } = useProfileContext()

  const [quantityForOrder, setQuantityForOrder] = useState<number>(10)
  function SeeRejectNote(e: React.MouseEvent<HTMLElement>) { }

  function EditLogo(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
    onEditDesign(design)
  }

  function AddToCart(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
    onAddToCart(design, quantityForOrder)
  }

  function onDeleteClick(e?: React.MouseEvent<HTMLElement>) {
    e?.stopPropagation()
    onDeleteDesign(design)
  }

  function handleApproveClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
    onApprove(design)
  }

  function handleRejectClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
    onReject(design)
  }

  const overallState = getDesignState(design)

  function draftActions() {
    return (
      <>
        <IconButton key={'approve'} onClick={EditLogo} icon={<EditIcon />} variant="success">
          Edit
        </IconButton>
        <IconButton icon={<TrashcanCrossIcon />} onClick={onDeleteClick} variant="error">
          Delete
        </IconButton>
      </>
    )
  }

  function rejectedActions() {
    return (
      <>
        <IconButton key={'see-note'} onClick={SeeRejectNote} icon={<NoteIcon />}>
          See Note
        </IconButton>
        <IconButton key={'approve'} onClick={EditLogo} icon={<EditIcon />} variant="success">
          Edit
        </IconButton>
        <IconButton icon={<TrashcanCrossIcon />} onClick={onDeleteClick} variant="error">
          Delete
        </IconButton>
      </>
    )
  }

  function pendingAction() {
    if (role == undefined || role == null) {
      return
    }

    if (isDesignApprovedByMe(design, role)) {
      return draftActions()
    }

    return (
      <>
        <IconButton
          onClick={handleApproveClick}
          icon={<FontAwesomeIcon icon={faCheck} />}
          variant="success">
          Approve
        </IconButton>
        <IconButton
          onClick={handleRejectClick}
          icon={<FontAwesomeIcon icon={faClose} />}
          variant="error">
          Reject
        </IconButton>
      </>
    )
  }

  function doneActions() {
    if (role === Role.Admin) {
      return <></>
    }

    return (
      <>
        <Stepper
          min={0}
          value={quantityForOrder}
          onValueChange={setQuantityForOrder}
          className="h-7"
        />
        <IconButton
          key={'add-order'}
          disabled={quantityForOrder <= 0}
          onClick={AddToCart}
          icon={<AddToCartIcon />}
          variant="success">
          Add to Order
        </IconButton>
      </>
    )
  }

  function getActions(state: DesignState) {
    switch (state) {
      case DesignState.Draft:
        return draftActions()
      case DesignState.Rejected:
      case DesignState.Pending:
        return rejectedActions()
      case DesignState.Approval:
        return pendingAction()
      case DesignState.Done:
        return doneActions()
    }
  }

  const actions = getActions(overallState)

  return (
    <StrucRow className={'h-[100px] items-start py-4'}>
      <StrucCol
        size={columns[nameOf<Design>((x) => x.displayImage)].weight}
        className={'mt-[-0.5rem]'}>
        <DesignImage design={design} className="pr-4" />
      </StrucCol>
      <StrucCol size={columns['product.mul'].weight}>
        <Tooltip title={design.product?.mul ?? '-'} placement={'top-start'}>
          <span className={'font-bold whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>
            {design.product?.mul ?? design.title}
          </span>
        </Tooltip>
      </StrucCol>
      <StrucCol size={columns[nameOf<Design>((x) => x.title)].weight}>
        <Tooltip title={design.title} placement={'top-start'}>
          <span className={'font-bold whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>
            {design.title}
          </span>
        </Tooltip>
      </StrucCol>
      <StrucCol size={columns[nameOf<Design>((x) => x.customer)].weight}>
        <CustomerTooltip customer={design.customer} />
      </StrucCol>
      <StrucCol size={columns['priceSort'].weight}>
        <DesignPrices design={design} />
      </StrucCol>
      <StrucCol size={columns[nameOf<Design>((x) => x.createdAtUtc)].weight}>
        {formatDate(design.createdAtUtc)}
      </StrucCol>
      <StrucCol size={columns[nameOf<Design>((x) => x.status)].weight}>
        <DesignStatusElement design={design} />
      </StrucCol>
      <StrucCol
        size={columns['action'].weight}
        className={'items-start !justify-around space-y-2 h-full'}>
        {actions}
      </StrucCol>
    </StrucRow>
  )
}
