import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { CartItemDesign } from '../models/CartItem'
import { ProductPreviewDesign } from './ProductPreviewDesign'
import { PriceLine } from '../../../components/Logo/LogoPrices'
import { useDesignActionHandler } from '../../design-list/hooks/useDesignActionHandler'

interface DesignDetailsProps {
  orderline: CartItemDesign
}

export function DesignDetails({ orderline }: DesignDetailsProps) {
  const designName = orderline.design.title
  const manufacturer = orderline.design.style.manufacturer.name
  const styleModel = orderline.design.style.modelAndNameWithoutHyphen
  const customer = orderline.design.customer.name
  const prices = DesignDetailsPrices(orderline);

  const actionHandler = useDesignActionHandler()

  function handleCopy() {
    actionHandler.handleCopyDesign(orderline.design)
      .then(d => {
        actionHandler.handleGoToUpdateDesign(d)
      })
  }

  return <div className={'space-y-2 w-full'}>
    <p className={'text-sm font-semibold'} style={{ lineHeight: '18px' }}>Product</p>
    <div className={'flex border rounded w-full space-x-5'}>
      <ProductPreviewDesign orderline={orderline} size={'lg'} />
      <div className={'flex flex-1 flex-col bg-gray-100 p-3'}>
        <div className={'text-xs p-1 flex flex-col gap-1'}>
          <p><b>Design Name</b> {designName}</p>
          <p><b>Manufacturer</b> {manufacturer}</p>
          <p><b>Model</b> {styleModel}</p>
          <p><b>Customer</b> {customer}</p>
        </div>
        <div className={'flex-1 text-xs pl-1 mt-2'}>
          <p><b>Design Price</b> <span className={'italic text-xxs font-normal'}>(ex moms og påvarmning)</span></p>
          {prices}
        </div>
        <div className={'flex-1'} />
        <PrimaryButton width={'full'} className={'uppercase'} onClick={handleCopy}>
          CLONE DESIGN
        </PrimaryButton>
      </div>
    </div>
  </div>
}

export function DesignDetailsPrices({ design }: CartItemDesign) {
  if (design.product?.totalPrices === undefined || design.product.totalPrices.length == 0) {
    return (<p className={'font-bold'}>No available prices</p>)
  }

  const productPrices = design.product.totalPrices.map(p => {
    const key = p.minQuantity
    return (
      <span key={key} className="pt-1 text-xs capitalize inline pr-1">
        {PriceLine({ price: p })}
      </span>
    )
  })

  return (
    <div className={"grid grid-rows-5 grid-flow-col gap-0"}>
      {productPrices}
    </div>
  )
}