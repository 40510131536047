import { JSX, useState } from 'react'
import { useDialog } from '../../../components/Dialogs/dialog-base'
import { PackagingOption } from '../useOrderConfirmationEditViewModel'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { OrderDialog } from './OrderDialog'
import { Packaging } from '../../../../core/models/entities/Order'

interface Props {
  component: JSX.Element
  open: () => void
}

export function useDeliveryPackagingOrderDialog({
  onContinue,
  onCancel,
  packaging
}: { onContinue: () => Promise<void>, onCancel?: () => void, packaging: PackagingOption }): Props {

  const [loading, setLoading] = useState(false)
  const [toggle, setToggle] = useState<boolean>(false);

  const dialog = useDialog()
  const { profile } = useProfileContext()

  function handleSubmit() {
    setLoading(true);
    onContinue().finally(() => {
      setLoading(false)
      setToggle(false);
      dialog.closeDialog()
    })
  }

  function handleToggle() {
    setToggle(!toggle)
  }

  function handleCancel() {
    if (onCancel) onCancel()
    dialog.closeDialog()
  }

  const getPackagingTypeText = () => {
    switch (packaging.type) {
      case Packaging.NoPackaging: return "no packaging"
      case Packaging.StandardBagPackaging: return "standard bag packaging"
      case Packaging.SpecificPackaging: return "specific packaging"
      default: return "unknown packaging"
    }
  }

  const getPackagingTermsText = () => {
    switch (packaging.type) {
      case Packaging.NoPackaging: return ""
      case Packaging.StandardBagPackaging: return profile!.orderOptions.orderOptionsTermsOfStandardPackaging
      case Packaging.SpecificPackaging: return profile!.orderOptions.orderOptionsTermsOfSpecificPackaging
      default: return ""
    }
  }

  const getPackagingAmountValue = () => {
    switch (packaging.type) {
      case Packaging.NoPackaging: return 0
      case Packaging.StandardBagPackaging: return profile!.orderOptions.orderOptionsStandardPackagingCost
      case Packaging.SpecificPackaging: return profile!.orderOptions.orderOptionsSpecificPackagingCost
      default: return 0
    }
  }

  return {
    component: <>
      <OrderDialog
        dialogVm={dialog}
        onToggle={handleToggle}
        onSubmit={handleSubmit}
        loading={loading}
        onCancel={handleCancel}
        toggle={toggle}
        title={packaging.title}
        termsText={getPackagingTermsText()}
        explanation={`The current fee for ${getPackagingTypeText()} is `}
        boldPrice={`DKK ${getPackagingAmountValue()} ${packaging.feeUnit}`}
      />
    </>,
    open: dialog.openDialog
  }
}