import { OrderLine } from '../../../../core/models/entities/Order'
import { GenericOrderLine, OrderLineHeaders, OrderLineHideColumns } from './OrderLineData'

export interface OrderLineListProps {
  hideColumns?: OrderLineHideColumns
  orderlines: OrderLine[]
}

export function OrderLineList({ hideColumns, orderlines }: OrderLineListProps) {

  // TODO Hack to remove pricePerPiece column.
  //  If this is ultimately desired, remove the pricePerPiece column from all OrderLine components.
  if (!hideColumns) hideColumns = { unitPrice: true }
  else hideColumns.unitPrice = true

  return <table className={"table-fixed border-separate -sm:hidden empty-cells-hidden mx-auto w-full"}>
    <thead className={'uppercase'}>
      <OrderLineHeaders hide={hideColumns} />
    </thead>
    <tbody>{orderlines.map((orderline, i) =>
      <GenericOrderLine key={orderline.id} orderline={orderline} hide={hideColumns} />)}
    </tbody>
  </table>
}