import { JetSportBox } from '../../components/BoxView/JetSportBox'
import { SecondaryButton } from '../../components/Buttons/SecondaryButton'
import { PrimaryButton } from '../../components/Buttons/PrimaryButton'
import { BoxHeader2 } from '../../components/BoxView/BoxHeader2'
import { BoxContent } from '../../components/BoxView/BoxContent'
import { BoxFooter } from '../../components/BoxView/BoxFooter'
import { OrderLineList } from './subcomponents/OrderLineList'
import { PriceTotal } from './subcomponents/PriceTotal'
import { Order, OrderLine } from '../../../core/models/entities/Order'
import { OrderDeliveryForm, DeliveryTypeOption, PackagingOption } from '../order-confirmation/useOrderConfirmationEditViewModel'
import { useProfileContext } from '../../../contexts/ProfileContext'
import { Role } from '../../../core/models/entities/Role'

export interface OrderDetailsComponentViewModel {
  orderNo: string
  orderlines: OrderLine[]
  handleBack: () => void
  handleConfirm: () => void
  form: OrderDeliveryForm
  deliveryTypeGroupList: DeliveryTypeOption[]
  packagingGroupList: PackagingOption[]
  handleOrderFormUpdate: (form: OrderDeliveryForm) => void
  subTotal?: number
  vat?: number
  totalAmount?: number
  readonly?: boolean
  order: Order
  onBack: () => void
  onReorder: () => void
}

export function OrderDetailsComponent({ order, orderlines, subTotal, vat, totalAmount, onBack, onReorder }: OrderDetailsComponentViewModel) {
  const { role } = useProfileContext()
  return <JetSportBox>
    <BoxHeader2 className={'uppercase text-sm flex justify-between items-center'}>
      <p><span className='font-bold'>Order </span>#{order.orderNumber}</p>
    </BoxHeader2>
    <BoxContent>
      <div className={'flex-1 flex-col my-10'}>
        <OrderLineList orderlines={orderlines} />
        <PriceTotal subTotal={subTotal || order.subTotal} vat={vat || order.vat} totalAmount={totalAmount || order.total} />
      </div>
    </BoxContent>
    <BoxFooter className={'justify-between'}>
      <SecondaryButton onClick={onBack}>Back</SecondaryButton>
      {role !== Role.Admin && <PrimaryButton onClick={onReorder}>Reorder</PrimaryButton>}
    </BoxFooter>
  </JetSportBox>
}


