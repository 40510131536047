import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Resource } from '../../core/models/wrappers/Resource'
import { OrderRepository } from '../../core/repositories/OrderRepository'
import { useApiClient } from '../clients/ApiClient'
import { PageQuery, toQueryParam } from '../../utils/models/pageQuery'
import { PagedResponse } from '../models/responses/pagedResponse'
import { DeliveryType, LogoPrice, Order, OrderDeliveryPreferences, Packaging } from '../../core/models/entities/Order'
import { CartItem, CartItemDesign, CartItemLogo } from '../../presentation/screens/order-create/models/CartItem'
import { mapToCreateOrder } from '../models/mappers/mapCartOrders'
import { mapLogoPrice, mapToCreateOrderDeliveryPreferences, mapToOrder } from '../models/mappers/mapOrder'
import { Verdict } from '../../presentation/components/ApprovalVoting/approval-voting'
import { DesignTemplate, LogoTemplate } from '../../core/models/entities/DesignTemplate'
import { dissmissToast, useLoadingToast } from '../../presentation/components/Toast/Toast'
import { OrderDto } from '../models/responses/OrderDto'

function useOrderRepository(): OrderRepository {
  const apiClient = useApiClient()

  const queryClient = useQueryClient()
  async function invalidateQueries() {
    await queryClient.refetchQueries({ queryKey: ['orders'], type: 'all', refetchPage: () => true })
  }

  function useAllOrders(query: PageQuery): Resource<PagedResponse<Order>> {
    return useQuery(['orders', toQueryParam(query)], () => apiClient.getAllOrders(query), {
      select: (data) => ({ ...data, results: data.results.map(mapToOrder) })
    })
  }

  function useOrder(orgId: number, orderId: number): Resource<Order> {
    return useQuery(['orders', orderId], () => apiClient.getOrder(orgId, orderId), {
      select: (data) => mapToOrder(data)
    })
  }

  async function createOrder(orgId: number, orderlines: CartItem[]): Promise<Order> {
    const createOrderDto = mapToCreateOrder(orderlines);
    return await apiClient.createOrder(orgId, createOrderDto).then(res => {
      invalidateQueries().then()
      return mapToOrder(res)
    })
  }

  // useOrderPrices is deprecated
  function useOrderPrices(orgId: number | undefined, orderlines: CartItem[]): Resource<LogoPrice[]> {
    // Spoof the order prices request and return an empty resource that does not load or fail.
    const resource = {
      data: [],
      isLoading: false,
      isSuccess: true,
      isError: false,
      refetch: () => Promise.resolve(resource)
    }
    return resource

    // TODO This code is no longer commissionable, as the endpoint has been discontinued.
    // The block should be removed when surrounding code has been refactored.
    // Improving and refactoring this code is currently out of scope.
    const createOrderDto = mapToCreateOrder(orderlines);
    return useQuery(['orders', orgId], () => apiClient.getOrderPrices(orgId, createOrderDto), {
      staleTime: 1,
      select: (data) => data?.map(x => mapLogoPrice(x)) ?? []
    })
  }

  const summarizeOrder = (orgId: number, items: CartItem[]) => {
    const request = {
      products: items.map((item) => {
        const id = (item as CartItemLogo)?.baseLogo?.product?.sku ??
          (item as CartItemDesign)?.design?.product?.mul ?? ''
        const storage = (item as CartItemLogo)?.toStorage ?? 0
        let delivery = (item as CartItemLogo)?.toDelivery
        if (!delivery) delivery = (item as CartItemDesign)?.instances
          ?.reduce((q, i) => q + i?.toDelivery ?? 0, 0)
        return {
          productNo: id,
          toDelivery: delivery,
          toStorage: storage
        }
      })
    }
    return apiClient.summarizeOrder(orgId, request)
  }

  async function commitOrder(orgId: number, orderId: number, orderDeliveryPreferences: OrderDeliveryPreferences): Promise<Order> {
    const createOrderDeliveryPreferencesDto = mapToCreateOrderDeliveryPreferences(orderDeliveryPreferences);
    return await apiClient.commitOrder(orgId, orderId, createOrderDeliveryPreferencesDto).then((res) => {
      invalidateQueries().then()
      return mapToOrder(res)
    })
  }

  async function placeVerdict(order: Order, verdict: Verdict): Promise<Order> {
    return await apiClient.placeVerdictOrder(order, verdict).then(res => {
      invalidateQueries().then()
      return res
    })
  }

  async function setPackagingAndDelivery(order: Order, deliveryType: DeliveryType, packaging: Packaging): Promise<OrderDto> {
    return await apiClient.setPackagingAndDelivery(order, deliveryType, packaging).then(res => {
      invalidateQueries().then()
      return res
    })
  }

  async function downloadTemplate(orgId: number, designs: CartItemDesign[]): Promise<Blob> {
    const toast = useLoadingToast("Downloading design template")
    return await apiClient.downloadTemplate(orgId, designs).then(res => {
      invalidateQueries().then()
      return res
    }).finally(() => dissmissToast(toast))
  }

  async function uploadDesignTemplate(orgId: number, spreadsheet: File): Promise<DesignTemplate> {
    const toast = useLoadingToast("Uploading design template")
    return await apiClient.uploadDesignTemplate(orgId, spreadsheet).then(res => {
      return { ...res }
    }).finally(() => dissmissToast(toast))
  }

  async function uploadLogoTemplate(orgId: number, spreadsheet: File): Promise<LogoTemplate> {
    const toast = useLoadingToast("Uploading design template")
    return await apiClient.uploadLogoTemplate(orgId, spreadsheet).then(res => {
      return { ...res }
    }).finally(() => dissmissToast(toast))
  }

  async function reorder(orgId: number, orderId: number): Promise<Order> {
    return await apiClient.reorder(orgId, orderId).then(res => {
      invalidateQueries().then()
      return mapToOrder(res)
    })
  }

  async function updateOrder(orgId: number, orderId: number, orderlines: CartItem[]): Promise<Order> {
    const updateOrderDto = mapToCreateOrder(orderlines);
    return await apiClient.updateOrder(orgId, orderId, updateOrderDto).then(res => {
      invalidateQueries().then()
      return mapToOrder(res)
    })
  }

  return {
    useAllOrders,
    useOrder,
    createOrder,
    commitOrder,
    summarizeOrder,
    placeVerdict,
    setPackagingAndDelivery,
    downloadTemplate,
    uploadDesignTemplate,
    uploadLogoTemplate,
    reorder,
    useOrderPrices,
    updateOrder
  }
}

export { useOrderRepository }